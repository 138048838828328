import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Box } from '@mui/material';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';

import { useProductCenterApi } from 'api';
import {
	unitEvcStatusQuery,
	unitKeys,
	unitProductInOperationDataQuery,
	unitStructureQuery,
} from 'api/queries';
import { AreaName } from 'domain/constants';
import {
	isControlSystemStatusCorrect,
	isProductInOperationButtonDisabled,
	showProductInOperationSection,
} from 'domain/unit';
import { OperationType } from 'library/models/enums/OperationType';
import { parseId } from 'library/utils/helpers/Unit';
import AuthorizeComponent from 'main/auth/AuthorizeComponent';
import { reactPlugin } from 'main/azureInsights/config';
import { productInOperationWizardPageUrl } from 'main/routes/urls/RouteUrls';
import {
	resetState,
	selectProductInOperationFiles,
} from 'modules/ProductInOperation/State';
import {
	attachmentFolderCreated,
	fileDeleted,
	fileUploaded,
} from 'modules/ProductInOperation/State/FilesSlice';
import { AdditionalFileUploadButton } from 'modules/UnitOverview/Components/AdditionalFileUploadButton';
import { AdditionalFilesSection } from 'modules/UnitOverview/Components/AdditionalFilesSection';
import { ChangedByInfoDisplay } from 'modules/UnitOverview/Components/ChangedByInfoDisplay';
import { DateCommentFilesDisplay } from 'modules/UnitOverview/Components/DateCommentFilesDisplay';
import { LocalLinkButton } from 'modules/UnitOverview/Components/LocalLinkButton';
import { IAdditionalFileUploadStateHandlers } from 'modules/UnitOverview/Components/UploadAdditionalFilesDialog';
import { EVCStatusError } from 'modules/UnitOverview/UnitInformation/Components/EVCStatusError';

const fileStateHandlers: IAdditionalFileUploadStateHandlers = {
	attachmentFolderCreated,
	fileDeleted,
	fileUploaded,
	fileStateSelector: selectProductInOperationFiles,
	reset: resetState,
};

const ProductInOperationSectionPage = () => {
	const { t } = useTranslation([
		'common',
		'commonWizard',
		'productInOperation',
		'unitOverview',
	]);
	const { unitId } = useParams();

	const unitIdParsed = parseId(unitId);
	const queryClient = useQueryClient();
	const { api } = useProductCenterApi();

	const productInOperationDataQueryResult = useSuspenseQuery(
		unitProductInOperationDataQuery(api, unitIdParsed)
	);
	const { data: unitData } = useSuspenseQuery(
		unitStructureQuery(unitIdParsed)
	);
	const { data: evcStatus } = useSuspenseQuery(
		unitEvcStatusQuery(api, unitIdParsed)
	);

	const isEVCCorrect = isControlSystemStatusCorrect(evcStatus);

	const isRegistrationButtonDisabled =
		isProductInOperationButtonDisabled(unitData);

	const isNotConnected = !showProductInOperationSection(unitData);

	const handleOnClose = useCallback(
		(isSubmitting: boolean) => {
			if (isSubmitting) {
				queryClient.invalidateQueries({
					queryKey: unitKeys.productInOperationData(unitIdParsed),
				});
			}
		},
		[queryClient, unitIdParsed]
	);

	if (isNotConnected) {
		return (
			<AuthorizeComponent
				areaName={AreaName.ProductInOperation}
				permission={OperationType.Create}>
				{!isEVCCorrect && <EVCStatusError />}
				<Box
					sx={{
						paddingTop: '1em',
					}}>
					<LocalLinkButton
						disabled={isRegistrationButtonDisabled || !isEVCCorrect}
						to={generatePath(productInOperationWizardPageUrl, {
							unitId: unitId || null,
						})}>
						{t('unitOverview:register-product-in-operation-button')}
					</LocalLinkButton>
				</Box>
			</AuthorizeComponent>
		);
	}

	return !!productInOperationDataQueryResult.data ? (
		<>
			<DateCommentFilesDisplay
				date={
					productInOperationDataQueryResult.data
						.productInOperationDate
				}
				dateLabel={t('commonWizard:product-in-operation-date')}
				comment={productInOperationDataQueryResult.data.comments}
				fileSet={productInOperationDataQueryResult.data.initialFileSet}
			/>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
					mb: 6,
					px: 8,
				}}>
				<ChangedByInfoDisplay
					label={'common:registered-by'}
					changedByInfo={
						productInOperationDataQueryResult.data.performedBy
					}
				/>
			</Box>
			<AdditionalFilesSection
				fileSets={
					productInOperationDataQueryResult.data.additionalFileSets
				}
				additionalAction={
					<AdditionalFileUploadButton
						handler={fileStateHandlers}
						onClose={handleOnClose}
					/>
				}
			/>
		</>
	) : null;
};

export default withAITracking(
	reactPlugin,
	ProductInOperationSectionPage,
	'UnitOverviewProductInOperationSectionPage',
	'application-insight-container'
);
