import { GridApiCommon, GridRowId } from '@mui/x-data-grid-pro';
import { memo } from 'react';

import { DEFAULT_MAX_VALUE, PlusMinusInput } from 'library/components/controls';

export type InstalledCellChangedEventHandler = (
	id: string,
	value: number
) => void;

export interface InstalledCellEvents {
	onInstalledCellChanged?: InstalledCellChangedEventHandler;
}
interface Props extends InstalledCellEvents {
	id: GridRowId;
	value: number;
	api: GridApiCommon;
}

const InstalledCell = ({
	id,
	api,
	value,
	onInstalledCellChanged,
}: Props): JSX.Element => {
	const handleChange = (changedValue: number) => {
		if (
			Number.isNaN(changedValue) ||
			changedValue === value ||
			changedValue < 0
		) {
			return;
		}

		api.updateRows([{ id, installed: changedValue }]);

		typeof onInstalledCellChanged === 'function' &&
			onInstalledCellChanged(id as unknown as string, changedValue);
	};

	const { serialNumber } = api.getRow(id);

	return (
		<PlusMinusInput
			value={value}
			onChange={handleChange}
			max={serialNumber ? 1 : DEFAULT_MAX_VALUE}
			unsigned
		/>
	);
};

export const MemoizedInstalledCell = memo(
	InstalledCell,
	({ value, id }, { value: nextValue, id: nextId }) =>
		value === nextValue && id === nextId
);
