import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Box, Typography } from '@mui/material';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';

import { useProductCenterApi } from 'api';
import {
	unitCommissioningDataQuery,
	unitEvcStatusQuery,
	unitKeys,
	unitStructureQuery,
} from 'api/queries';
import { AreaName } from 'domain/constants';
import {
	isCommissioningButtonDisabled,
	isControlSystemStatusCorrect,
	showCommissioningSection,
} from 'domain/unit';
import { OperationType } from 'library/models/enums/OperationType';
import { parseId } from 'library/utils/helpers/Unit';
import AuthorizeComponent from 'main/auth/AuthorizeComponent';
import { reactPlugin } from 'main/azureInsights/config';
import { commissioningWizardPageUrl } from 'main/routes/urls/RouteUrls';
import {
	resetState,
	selectCommissioningFiles,
} from 'modules/Commissioning/State';
import {
	attachmentFolderCreated,
	fileDeleted,
	fileUploaded,
} from 'modules/Commissioning/State/FilesSlice';
import { isUnitIndustrial, isUnitMarine } from 'modules/Shared/State/unit';
import { Questions } from 'modules/UnitOverview/Commissioning/Components/Questions';
import { getConsentOptions } from 'modules/UnitOverview/Commissioning/Components/consentOptions';
import { AdditionalFileUploadButton } from 'modules/UnitOverview/Components/AdditionalFileUploadButton';
import { AdditionalFilesSection } from 'modules/UnitOverview/Components/AdditionalFilesSection';
import { ChangedByInfoDisplay } from 'modules/UnitOverview/Components/ChangedByInfoDisplay';
import { DateCommentFilesDisplay } from 'modules/UnitOverview/Components/DateCommentFilesDisplay';
import { LocalLinkButton } from 'modules/UnitOverview/Components/LocalLinkButton';
import { RadioGroupDisplay } from 'modules/UnitOverview/Components/RadioGroupDisplay';
import { IAdditionalFileUploadStateHandlers } from 'modules/UnitOverview/Components/UploadAdditionalFilesDialog';
import { EVCStatusError } from 'modules/UnitOverview/UnitInformation/Components/EVCStatusError';

const fileStateHandlers: IAdditionalFileUploadStateHandlers = {
	attachmentFolderCreated,
	fileDeleted,
	fileUploaded,
	fileStateSelector: selectCommissioningFiles,
	reset: resetState,
};

const CommissioningSectionPage = (): JSX.Element | null => {
	const { t } = useTranslation([
		'common',
		'commonWizard',
		'commissioning',
		'unitOverview',
	]);
	const { unitId } = useParams();
	const unitIdParsed = parseId(unitId);
	const consentOptions = useMemo(() => getConsentOptions(t), [t]);

	const queryClient = useQueryClient();
	const { api } = useProductCenterApi();
	const { data: commissioningData } = useSuspenseQuery(
		unitCommissioningDataQuery(api, unitIdParsed)
	);
	const { data } = useSuspenseQuery(unitStructureQuery(unitIdParsed));
	const { data: evcStatus } = useSuspenseQuery(
		unitEvcStatusQuery(api, unitIdParsed)
	);

	const isEVCCorrect = isControlSystemStatusCorrect(evcStatus);

	const isRegistrationButtonDisabled = isCommissioningButtonDisabled(data);
	const isNotConnected = !showCommissioningSection(data);

	const handleOnClose = useCallback(
		(isSubmitting: boolean) => {
			if (isSubmitting) {
				queryClient.invalidateQueries({
					queryKey: unitKeys.commissioningData(unitIdParsed),
				});
			}
		},
		[queryClient, unitIdParsed]
	);

	if (isNotConnected) {
		return (
			<AuthorizeComponent
				areaName={AreaName.Commissioning}
				permission={OperationType.Create}>
				{!isEVCCorrect && <EVCStatusError />}
				<Box
					sx={{
						paddingTop: '1em',
					}}>
					<LocalLinkButton
						disabled={isRegistrationButtonDisabled || !isEVCCorrect}
						to={generatePath(commissioningWizardPageUrl, {
							unitId: unitId || null,
						})}>
						{t('unitOverview:perform-commissioning-button')}
					</LocalLinkButton>
				</Box>
			</AuthorizeComponent>
		);
	}

	return !!commissioningData ? (
		<>
			<DateCommentFilesDisplay
				date={commissioningData.commissioningDate}
				dateLabel={t('commonWizard:commissioning-date')}
				comment={commissioningData.comments}
				fileSet={commissioningData.initialFileSet}
			/>
			<Box
				sx={{
					display: 'grid',

					gridTemplateColumns: {
						xs: '1fr',
						sm: '1fr 1fr',
					},

					gap: { xs: 2, sm: 4 },
					mb: 6,
				}}>
				{isUnitMarine(data?.mainSegment) && (
					<>
						<Typography>
							{t('commissioning:wasSeaTrialPerformed')}
						</Typography>
						<RadioGroupDisplay
							value={commissioningData.wasSeaTrialPerformed}
							radioLabelsList={consentOptions}
							row={true}
						/>
					</>
				)}
				<Typography>
					{t('unitOverview:commissioning-information-shared-text')}
				</Typography>
				<RadioGroupDisplay
					value={commissioningData.isInformationSharedWithCustomer}
					radioLabelsList={consentOptions}
					row={true}
				/>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
					my: 2,
					pr: { xs: 0, sm: 10 },
				}}>
				<ChangedByInfoDisplay
					label={'unitOverview:commissioned-by'}
					changedByInfo={commissioningData.performedBy}
				/>
			</Box>
			{commissioningData.commissioningQuestions &&
				!isUnitIndustrial(data?.mainSegment) && (
					<Box
						sx={{
							mb: 6,
						}}>
						<Questions
							questions={commissioningData.commissioningQuestions}
						/>
					</Box>
				)}
			<AdditionalFilesSection
				fileSets={commissioningData.additionalFileSets}
				additionalAction={
					<AdditionalFileUploadButton
						handler={fileStateHandlers}
						onClose={handleOnClose}
					/>
				}
			/>
		</>
	) : null;
};

export default withAITracking(
	reactPlugin,
	CommissioningSectionPage,
	'UnitOverviewCommissioningSectionPage',
	'application-insight-container'
);
