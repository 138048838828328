import { useState } from 'react';

export interface ContextMenuStateHookReturnType {
	open: boolean;
	onClose: () => void;
	onOpen: (event: React.MouseEvent<HTMLElement>) => void;
	anchorElement: HTMLElement | null;
}

export const useContextMenuState = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return {
		open,
		onClose: handleClose,
		onOpen: handleClick,
		anchorElement: anchorEl,
	};
};
