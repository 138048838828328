import { Tooltip } from '@mui/material';
import {
	GridCsvExportMenuItem,
	GridCsvExportOptions,
	GridSlots,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExportContainer,
	GridToolbarFilterButton,
	GridToolbarQuickFilter,
	PropsFromSlot,
} from '@mui/x-data-grid-pro';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface DataGridToolbarProps extends PropsFromSlot<GridSlots['toolbar']> {
	exportComponent?: ReactNode;
}

export const DataGridToolbar = ({
	children,
	exportComponent,
}: DataGridToolbarProps) => {
	const { t } = useTranslation(['common']);

	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<Tooltip title={t('common:change-layout')}>
				<GridToolbarDensitySelector />
			</Tooltip>
			{exportComponent}
			<GridToolbarQuickFilter />
			{children}
		</GridToolbarContainer>
	);
};

interface DataGridToolbarWithExport
	extends PropsFromSlot<GridSlots['toolbar']> {
	csvOptions?: GridCsvExportOptions;
	fileName?: string;
}

export const DataGridToolbarWithCsvExport = ({
	fileName,
	csvOptions = {
		delimiter: ';',
		includeColumnGroupsHeaders: false,
		allColumns: true,
	},
}: DataGridToolbarWithExport) => (
	<DataGridToolbar
		exportComponent={
			<GridToolbarExportContainer>
				<GridCsvExportMenuItem options={{ ...csvOptions, fileName }} />
			</GridToolbarExportContainer>
		}
	/>
);
