import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { useTranslation } from 'react-i18next';

import { reactPlugin } from 'main/azureInsights/config';
import { useNumberParam } from 'modules/Shared/Components/Hooks/useNumberParam';
import { LegacySection } from 'modules/UnitOverview/Components/LegacySection';

const ServiceAgreementsSectionPage = (): JSX.Element => {
	const { t } = useTranslation(['unitOverview']);
	const unitId = useNumberParam();

	return (
		<LegacySection
			unitId={unitId}
			mvcAction={'UnitOverview/ServiceAgreementOverview'}
			buttonLabel={t('unitOverview:view-service-agreements')}
		/>
	);
};

export default withAITracking(
	reactPlugin,
	ServiceAgreementsSectionPage,
	'UnitOverviewServiceAgreementsSectionPage',
	'application-insight-container'
);
