import { Box, Button, Typography } from '@mui/material';
import { HTMLAttributeAnchorTarget } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BaseDialog } from 'library/components/controls';
import {
	getLinkCreateNewServiceProtocol,
	getLinkOpenInitiatedServiceProtocol,
} from 'library/components/link';

export type ServiceProtocolIdProp = {
	serviceProtocolId: number;
};

export const GenerateProtocolWarningDialog = ({
	serviceProtocolId,
	serviceScheduledDate,
	chassisId,
	drivelineId,
	onClose,
	linkTarget = '_self',
}: ServiceProtocolIdProp & {
	chassisId: string;
	drivelineId: number;
	onClose: () => void;
	serviceScheduledDate?: Date | null;
	linkTarget?: HTMLAttributeAnchorTarget | undefined;
}): JSX.Element => {
	const { t } = useTranslation(['common', 'serviceProtocol']);

	return (
		<BaseDialog
			title={t('common:warning') + ` - ${chassisId}`}
			onClose={onClose}
			sx={{
				'& .base-dialog__title': {
					bgcolor: 'background.default',
				},
			}}>
			<Typography
				sx={{
					py: 2,
				}}>
				{t('serviceProtocol:generate-popup-message')}
			</Typography>
			<Box
				sx={{
					display: 'flex',
					gap: 1,
				}}>
				<Button
					component={Link}
					variant="outlined"
					color="secondary"
					target={linkTarget}
					to={getLinkOpenInitiatedServiceProtocol(
						serviceProtocolId,
						drivelineId
					)}
					onClick={onClose}>
					{t('serviceProtocol:generate-popup-open-protocol-button')}
				</Button>

				<Button
					component={Link}
					variant="outlined"
					target={linkTarget}
					to={getLinkCreateNewServiceProtocol(
						drivelineId,
						chassisId,
						serviceScheduledDate
					)}
					onClick={onClose}>
					{t('serviceProtocol:generate-popup-create-protocol-button')}
				</Button>
			</Box>
		</BaseDialog>
	);
};
