import { Draft, PayloadAction, Update, createSlice } from '@reduxjs/toolkit';

import { UnitOtherPartDto } from 'api';
import { DimensionsType } from 'api/responses/models/Unit/UnitMeasurement';
import { SubmitErrorResponse } from 'library/exceptions/ValidationErrors';
import {
	ApiStatus,
	UnitDataFormTypes,
	commonInitialState,
} from 'modules/Shared/State/unit';

import { otherPartsList } from './Entity';
import {
	addOtherProduct,
	fetchOtherPartsAsync,
	resetUnitCreationState,
	submitUnitRegistration,
} from './Thunks';
import { CreateUnitState } from './Types';

export const DEFAULT_DIMENSIONS_TYPE: DimensionsType = 'Estimated';

export const initialState: CreateUnitState = {
	infoSource: '',
	otherParts: otherPartsList.getInitialState(),
	otherPartsProductIndividualSerialNumbers: [],
	unitData: {
		hullIdStandard: null,
		dimensionsType: DEFAULT_DIMENSIONS_TYPE,
		length: '',
		lengthUnit: '',
		width: '',
		widthUnit: '',
		displacement: '',
		displacementUnit: '',
		lineOfBusiness: '',
		unitPurpose: '',
	},
	...commonInitialState,
};

export const unitCreationSlice = createSlice({
	name: 'unitCreation',
	initialState,
	reducers: {
		changeOtherPartsInstalledAmount: (
			{ otherParts },
			{
				payload: { id, installed },
			}: PayloadAction<{ id: string; installed: number }>
		) => {
			if (Number.isNaN(installed) || installed < 0) {
				return;
			}

			const updateObj: Update<UnitOtherPartDto, string> = {
				id,
				changes: { installed },
			};

			otherPartsList.updateOne(otherParts, updateObj);
		},
		clearOtherParts: ({ otherParts }: Draft<CreateUnitState>) => {
			otherPartsList.removeAll(otherParts);
		},
		resetOtherParts: ({ otherParts }: Draft<CreateUnitState>) => {
			otherPartsList.removeAll(otherParts);
		},
		resetOtherPartsProductIndividualSerialNumbers: (
			state: Draft<CreateUnitState>
		) => {
			setOtherPartsProductIndividualSN(state, []);
		},
		setOtherPartsProductIndividualSerialNumbers: (
			state: Draft<CreateUnitState>,
			{ payload }: PayloadAction<string[]>
		) => {
			setOtherPartsProductIndividualSN(state, payload);
		},
		updateFormData: (
			state,
			{ payload }: PayloadAction<UnitDataFormTypes>
		) => {
			state.unitData = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchOtherPartsAsync.pending, (state) => {
				state.status = ApiStatus.Pending;
			})
			.addCase(fetchOtherPartsAsync.fulfilled, (state, action) => {
				state.status = ApiStatus.Fulfilled;
				otherPartsList.upsertMany(state.otherParts, action.payload);
			})
			.addCase(addOtherProduct.fulfilled, (state, action) => {
				if (action.payload) {
					otherPartsList.addOne(state.otherParts, action.payload);
				}
			})
			.addCase(submitUnitRegistration.fulfilled, (state, action) => {
				if (action.payload) {
					state.createdUnitId = action.payload;
				}
			})
			.addCase(submitUnitRegistration.rejected, (state, { payload }) => {
				state.submitErrors = payload as SubmitErrorResponse;
			})
			.addCase(resetUnitCreationState.fulfilled, (state) => ({
				...initialState,
				createdUnitId: state.createdUnitId, // needed for the last step
			}));
	},
});

export const {
	actions: {
		changeOtherPartsInstalledAmount,
		clearOtherParts,
		resetOtherParts,
		resetOtherPartsProductIndividualSerialNumbers,
		setOtherPartsProductIndividualSerialNumbers,
		updateFormData,
	},
} = unitCreationSlice;

export default unitCreationSlice.reducer;

function setOtherPartsProductIndividualSN(
	state: Draft<CreateUnitState>,
	payload: string[]
) {
	state.otherPartsProductIndividualSerialNumbers = payload;
}
