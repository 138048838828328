import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { PageButtons } from 'library/components/controls';
import { useHistory } from 'library/hooks/useHistory';
import { ErrorContent } from 'main/errors/ErrorContent';
import { home_HomeUrl, notFoundUrl } from 'main/routes/urls/RouteUrls';

export const NotFoundPage = (): JSX.Element => {
	const { t } = useTranslation(['error', 'home']);
	const { current, previous } = useHistory();
	const navigate = useNavigate();
	const { pathname, state } = useLocation();

	useEffect(() => {
		if (current?.pathname === notFoundUrl && !previous) {
			navigate(home_HomeUrl);
		}
	}, [navigate, current, previous]);

	const fallBackPath = previous?.pathname ?? '';

	return (
		<ErrorContent
			title={t('error:not-found-title')}
			subtitle={
				state?.message ||
				t('error:not-found-subtitle', {
					pathname:
						pathname !== notFoundUrl ? pathname : fallBackPath,
				})
			}>
			<PageButtons
				primaryButtonLabel={t('home:root-title')}
				primaryButtonAction={() => {
					navigate(home_HomeUrl);
				}}
				withDivider={false}
			/>
		</ErrorContent>
	);
};
