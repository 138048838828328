import { SvgIcon, SvgIconProps } from '@mui/material';

const SpreadVolvoPentaLogo = (props: SvgIconProps) => (
	<SvgIcon
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		viewBox="0 0 2118.4 73.7"
		{...props}>
		<path
			d="M1475.21,28.66V1.74h-96.89V12.5H1394v48.46h-15.69v10.87h96.89V39.71h-10.72v2.58c0,10.39-7.07,18.63-16.2,18.63h-21.97
	V39.71h21.97V29.39h-21.97V12.5h22.58c7.79,0,14.06,5.79,15.58,16.16H1475.21z"
		/>
		<path
			d="M1874.72,12.5c9.05,0,16.36,7.13,16.36,16.16h10.73V1.74h-97.43v26.92h10.72c0-9.04,7.35-16.11,16.39-16.16h5.44v48.46
	h-15.76v10.87h63.87V60.95h-15.78V12.5H1874.72z"
		/>
		<path
			d="M1216.4,34.27V12.5h9.57c4.41,0,8.37,1.57,10.84,4.13c1.71,1.72,2.79,4.19,2.79,6.9c0,2.89-1.22,5.46-3.1,7.22
	c-2.47,2.29-6.28,3.52-10.52,3.52L1216.4,34.27z M1272.1,23.65c0-5.38-2.14-10.24-5.57-13.7c-4.89-5.04-12.71-8.2-21.5-8.2h-7.19
	h-69.86V12.5h16.06v48.46h-16.06v10.87h64.8V60.95h-16.39V44.93h21.33h7.32c8.43,0,15.96-2.44,20.88-6.99
	C1269.65,34.48,1272.1,29.36,1272.1,23.65z"
		/>
		<path
			d="M1999.82,60.95h-16.1c0,0,0,8.13,0,10.87c1.92,0.01,45.74,0.01,48.42,0.01c0.01-2.49,0-10.88,0-10.88l-13.96,0.04
	c0.32-0.63,2.59-4.82,5.62-10.4h35.68l5.71,10.37l-16.92,0v10.87h70.14V60.95h-16.02l-32.66-59.21h-37.83L1999.82,60.95z
	 M2041.53,17.85l11.88,21.63h-23.59L2041.53,17.85z"
		/>
		<polygon
			points="1630.54,71.83 1630.54,60.95 1614.71,60.95 1614.71,25.93 1667.82,71.83 1690.46,71.83 1690.46,12.5 1706.48,12.5 
	1706.48,1.74 1658.1,1.74 1658.1,12.5 1674.06,12.5 1674.06,39.2 1630.72,1.74 1582.37,1.74 1582.37,12.5 1598.3,12.5 1598.3,60.95 
	1582.37,60.95 1582.37,71.83 "
		/>
		<g id="BLOCK_x5F_RECORD_2_">
			<polygon
				points="16.07,12.51 48.74,71.88 86.58,71.91 118.7,12.51 134.8,12.51 134.8,1.75 86.37,1.75 86.37,12.51 100.36,12.51 
		76.97,55.75 53.27,12.51 70.26,12.51 70.26,1.75 0,1.75 -0.01,12.51 	"
			/>
			<path
				d="M432.39,12.49l-0.01-10.74l64.79,0.01v10.73h-16.14v48.46H496c13.86,0,22.95-9.16,22.95-21.58h10.69v32.45l-97.25,0.01
		V60.96h16.33l-0.02-48.48H432.39z"
			/>
			<g>
				<path
					d="M275.29,0.27c-32.53,0-49.81,16.23-49.81,36.59c0,20.65,16.64,37.11,50,37.11c33.35,0,49.81-16.46,49.81-37.11
			C325.29,16.5,307.83,0.27,275.29,0.27z M274.93,62.82c-11.88-0.21-17.1-13.34-16.88-26.39c0.24-13.51,6.7-25.29,17.83-25.1
			c11.13,0.2,17.01,12.2,16.77,25.71C292.42,49.78,287.16,63.05,274.93,62.82z"
				/>
			</g>
			<polygon
				points="640.15,12.51 672.83,71.88 710.66,71.91 742.78,12.51 758.88,12.51 758.88,1.75 710.46,1.75 710.46,12.51 
		724.44,12.51 701.05,55.75 677.35,12.51 694.35,12.51 694.35,1.75 624.09,1.75 624.07,12.51 	"
			/>
			<g>
				<path
					d="M899.39,0.27c-32.53,0-49.81,16.23-49.81,36.59c0,20.65,16.64,37.11,50,37.11c33.35,0,49.81-16.46,49.81-37.11
			C949.39,16.5,931.93,0.27,899.39,0.27z M899.03,62.82c-11.88-0.21-17.1-13.34-16.88-26.39c0.24-13.51,6.7-25.29,17.83-25.1
			c11.13,0.2,17,12.2,16.76,25.71C916.53,49.78,911.27,63.05,899.03,62.82z"
				/>
			</g>
		</g>
	</SvgIcon>
);

export default SpreadVolvoPentaLogo;
