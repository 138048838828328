import { Box } from '@mui/material';

import { LegacyButton } from 'library/components/link/LegacyButton';

export const LegacySection = ({
	unitId,
	mvcAction,
	buttonLabel,
	buttonDisabled,
}: {
	unitId: number;
	mvcAction: string;
	buttonLabel: string;
	buttonDisabled?: boolean;
}): JSX.Element => (
	<Box>
		<LegacyButton
			disabled={buttonDisabled}
			label={buttonLabel}
			mvcAction={mvcAction}
			unitId={unitId}
		/>
	</Box>
);
