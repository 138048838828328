import { styled } from '@mui/material';

export const DimensionBox = styled('div')(({ theme }) => ({
	display: 'flex',
	gap: theme.spacing(2),

	'& > *': {
		flex: 1,
	},
}));
