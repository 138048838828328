import { Box, Paper, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import {
	useQueryLineOfBusiness,
	useQueryUnitPurposes,
} from 'api/queries/ProductQueries';
import { convertListItemToOption } from 'api/responses/models/Common/ListItem';
import {
	filterAndSortLineOfBusiness,
	filterAndSortUnitPurpose,
} from 'domain/unit/Unit';
import {
	ControlledSelect,
	ControlledTextField,
} from 'library/components/controls';
import { MainSegment, UnitDataFormTypes } from 'modules/Shared/State/unit';

interface UnitGeneralSectionProps {
	consumerLineOfBusiness: string;
	control: Control<UnitDataFormTypes>;
	mainSegment: MainSegment | null;
}

export const UnitGeneralSection = ({
	consumerLineOfBusiness,
	control,
	mainSegment,
}: UnitGeneralSectionProps) => {
	const { api } = useProductCenterApi();
	const { data: lineOfBusiness } = useQueryLineOfBusiness(api, mainSegment);
	const { data: unitPurposes } = useQueryUnitPurposes(api);
	const { t } = useTranslation(['unitCreation', 'commonUnit']);
	const lineOfBusinessOptions = useMemo(
		() => filterAndSortLineOfBusiness(lineOfBusiness || [], mainSegment),
		[lineOfBusiness, mainSegment]
	);
	const unitPurposesOptions = useMemo(
		() =>
			filterAndSortUnitPurpose(
				unitPurposes || [],
				consumerLineOfBusiness
			),
		[unitPurposes, consumerLineOfBusiness]
	);

	return (
		<Box
			component={Paper}
			variant="flatGrey"
			sx={{
				display: 'grid',

				gridTemplateColumns: {
					xs: 'repeat(1, 1fr)',
					sm: 'repeat(2, 1fr)',
					md: 'repeat(4, 1fr)',
				},

				gap: 2,
				p: 2,
			}}>
			<Box
				sx={{
					gridColumn: { sm: 'span 2', md: '1' },
					gridRow: '1/3',
				}}>
				<Typography
					variant="h5"
					sx={{
						pb: 1,
					}}>
					{t('unitCreation:general-section-title')}
				</Typography>
				<Typography variant="body2">
					{t('unitCreation:general-section-description')}
				</Typography>
			</Box>
			<ControlledTextField
				id="text-field-unit-name"
				name="unitName"
				label={t('commonUnit:name')}
				control={control}
				size="small"
			/>
			<ControlledTextField
				id="text-field-registration-number"
				name="registrationNumber"
				label={t('commonUnit:registration-number')}
				control={control}
				size="small"
			/>
			<ControlledSelect
				id="line-of-business-select"
				name="lineOfBusiness"
				label={t('unitCreation:configuration-select-line-of-business')}
				control={control}
				options={(lineOfBusinessOptions || []).map(
					convertListItemToOption
				)}
				size="small"
				sx={{ gridColumn: { sm: '1', md: '2' } }}
				validation={{ required: 'common:validation-error-message' }}
			/>
			<ControlledSelect
				id="unit-purpose-select"
				name="unitPurpose"
				label={t('unitCreation:configuration-select-unit-purpose')}
				control={control}
				options={unitPurposesOptions.map(convertListItemToOption)}
				size="small"
				validation={{ required: 'common:validation-error-message' }}
			/>
		</Box>
	);
};
