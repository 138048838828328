import { MoreVert, SvgIconComponent } from '@mui/icons-material';
import {
	IconButton,
	SvgIconPropsSizeOverrides,
	Typography,
	TypographyProps,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { noop } from 'lodash';
import { FC } from 'react';

import { PropWithTestId, SxProps, Sxable } from 'library/types';

export interface AnchorProps extends PropWithTestId, Sxable {
	disabled?: boolean;
	onClick?: () => void;
}

interface IconAnchorProps extends AnchorProps {
	fontSize?: OverridableStringUnion<
		'inherit' | 'large' | 'medium' | 'small',
		SvgIconPropsSizeOverrides
	>;
	Icon?: SvgIconComponent;
}

interface TextAnchorProps
	extends AnchorProps,
		Omit<TypographyProps, 'onClick' | 'sx'> {
	text: string;
}

const defaultIconSx: SxProps = ({
	palette: {
		secondary: { main },
	},
}) => ({
	'&:hover': {
		cursor: 'pointer',
		color: main,
	},
});

const defaultTextSx: SxProps = {
	cursor: 'pointer',
	'&:hover': {
		textDecoration: 'underline',
	},
};

export const IconAnchor: FC<IconAnchorProps> = ({
	dataTestId,
	disabled = false,
	fontSize = 'medium',
	onClick = noop,
	sx: style = defaultIconSx,
	Icon = MoreVert,
}) => (
	<IconButton
		data-testid={dataTestId}
		disabled={disabled}
		onClick={onClick}
		sx={style}>
		<Icon fontSize={fontSize} />
	</IconButton>
);

export const TextAnchor: FC<TextAnchorProps> = ({
	dataTestId,
	sx = defaultTextSx,
	text,
	...rest
}) => (
	<Typography sx={sx} data-testid={dataTestId} {...rest}>
		{text}
	</Typography>
);
