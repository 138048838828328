export const replace = (
	url: string,
	regex: RegExp,
	replacement = '',
	skip = 0
) => {
	let matchIndex = 0;

	return url.replace(
		regex,
		skip
			? (match: string) => {
					matchIndex++;
					return matchIndex > skip ? replacement : match;
				}
			: () => replacement
	);
};
