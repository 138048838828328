import { EditOutlined } from '@mui/icons-material';
import { Box, IconButton, Paper } from '@mui/material';

import { EndCustomerDto } from 'api/responses/models/AddEndCustomer';
import { AreaName } from 'domain/constants';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import Authorize from 'main/auth/Authorize';
import {
	CustomerBox,
	CustomerBoxProps,
} from 'modules/EndCustomer/Shared/CustomerBox';

import {
	ChangedByInfoDisplay,
	ChangedByInfoDisplayProps,
} from './ChangedByInfoDisplay';

export const ConnectedCustomerCardDisplay = ({
	customer,
	isOwner,
	onEditCustomer,
	changedByInfo,
}: {
	onEditCustomer: (customer: EndCustomerDto) => void;
} & CustomerBoxProps &
	ChangedByInfoDisplayProps): JSX.Element => {
	return (
		<Paper
			className="customer-card-display__root"
			variant="flatGrey"
			sx={{
				position: 'relative',
				display: 'grid',
				gridTemplateColumns: {
					xs: 'minmax(0, 1fr)',
					sm: 'minmax(0, 2fr) 1fr',
				},
				alignItems: 'end',
				gap: 2,
				flex: 1,
				'& > .text-display__root': {
					justifySelf: 'end',
				},
			}}>
			<Box
				sx={{
					alignSelf: 'start',
				}}>
				<CustomerBox customer={customer} isOwner={isOwner} />
			</Box>
			<ChangedByInfoDisplay changedByInfo={changedByInfo} />
			<Authorize
				area={createArea(AreaName.Consumer, OperationType.Write)}
				displayIntermediatePages={false}
				redirectWhenNotAuthorized={false}>
				<IconButton
					disabled={customer == null || !customer.isEditable}
					onClick={() => customer && onEditCustomer(customer)}
					sx={{
						position: 'absolute',
						top: 0,
						right: 0,
					}}>
					<EditOutlined />
				</IconButton>
			</Authorize>
		</Paper>
	);
};
