import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useProductCenterApi } from 'api';
import { unitVariantsQuery } from 'api/queries';
import { NoDataAvailable } from 'library/components/controls';
import { reactPlugin } from 'main/azureInsights/config';
import { useNumberParam } from 'modules/Shared/Components/Hooks/useNumberParam';
import { VariantsDataGrid } from 'modules/UnitOverview/Components/VariantsDataGrid';

const VariantsSectionPage = (): JSX.Element => {
	const { api } = useProductCenterApi();

	const unitId = useNumberParam('unitId');

	const variantsQuery = useSuspenseQuery(unitVariantsQuery(api, unitId));

	return variantsQuery.data?.rows ? (
		<VariantsDataGrid model={variantsQuery.data} />
	) : (
		<NoDataAvailable />
	);
};

export default withAITracking(
	reactPlugin,
	VariantsSectionPage,
	'UnitOverviewVariantsSectionPage',
	'application-insight-container'
);
