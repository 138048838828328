import { PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export const PortalComponent = ({
	children,
	containerId,
}: PropsWithChildren<{
	containerId: string;
}>): JSX.Element | null => {
	const [filterButtonContainer, setFilterButtonContainer] =
		useState<HTMLElement | null>(null);

	useEffect(() => {
		// have to wait for browser to display the element, so we could find it by id
		if (filterButtonContainer == null) {
			setFilterButtonContainer(document.getElementById(containerId));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return filterButtonContainer
		? createPortal(children, filterButtonContainer)
		: null;
};
