import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NoDataAvailable = () => {
	const { t } = useTranslation(['common']);

	return (
		<Typography
			variant="body1"
			sx={{
				my: 1,
			}}>
			{t('common:no-data-available-message')}
		</Typography>
	);
};
