import { convertWeightToKilograms } from 'api/responses/models/Unit/UnitMeasurement';
import { UpdateUnitInfoRequest } from 'api/responses/models/Unit/UpdateUnitInfoRequest';
import { getBrand, getModel } from 'domain/unit/brandAndModel';
import { isIsoHullId, isUsCoastGuardHullId } from 'domain/unit/hullIdSelector';
import { UnitDataFormTypes } from 'modules/Shared/State/unit';

export default function mapUnitInfoFormToRequest(
	data: UnitDataFormTypes,
	unitId: number
): UpdateUnitInfoRequest {
	const { unitOfWeight, weight } = convertWeightToKilograms(
		parseFloat(data.displacement),
		data.displacementUnit
	);

	return {
		unitId,
		unitInfo: {
			name: data.unitName as string | null,
			registrationNumber: data.registrationNumber as string | null,
			hullIdCoastGuard: isUsCoastGuardHullId(data.hullIdStandard)
				? data.hullId
				: undefined,
			isoHullId: isIsoHullId(data.hullIdStandard)
				? data.hullId
				: undefined,
			hullMachineId: data.machineId,
			imoNumber: data.imoNumber,
			mmsiNumber: data.mmsiNumber,
			callSign: data.callSign as string | null,
			brand: getBrand(data.brand, data.customBrand),
			model: getModel(data.model, data.customModel),
			overallLength: parseFloat(data.length),
			unitOfLength: data.lengthUnit,
			width: parseFloat(data.width),
			unitOfWidth: data.widthUnit,
			weight: weight,
			unitOfWeight: unitOfWeight,
			weightType: data.dimensionsType,
			purpose: data.unitPurpose,
			lineOfBusiness: data.lineOfBusiness,
		},
	};
}
