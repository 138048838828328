// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/performance.md
import {
	IPublicClientApplication,
	NavigationClient,
	NavigationOptions,
} from '@azure/msal-browser';
import { PropsWithChildren } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { BASE_URL } from 'main/routes';

export function ClientSideNavigation({
	instance,
	children,
}: PropsWithChildren<{
	instance: IPublicClientApplication;
}>): JSX.Element {
	const navigate = useNavigate();
	const navigationClient = new CustomNavigationClient(navigate);
	instance.setNavigationClient(navigationClient);

	return <>{children}</>;
}

/**
 * This is an example for overriding the default function MSAL uses to navigate to other urls in your webpage
 */
export class CustomNavigationClient extends NavigationClient {
	navigate;

	constructor(navigate: NavigateFunction) {
		super();
		this.navigate = navigate;
	}

	/**
	 * Navigates to other pages within the same web application
	 * You can use the useNavigate hook provided by react-router-dom to take advantage of client-side routing
	 * @param url
	 * @param options
	 */
	async navigateInternal(url: string, options: NavigationOptions) {
		const relativePath = url.replace(BASE_URL, '');

		if (options.noHistory) {
			await this.navigate(relativePath, { replace: true });
		} else {
			await this.navigate(relativePath);
		}

		return false;
	}
}
