import { refreshVppnToken } from 'api/axios/vppn';
import {
	decode,
	getExpDateDiffInMs,
	isExpired,
} from 'library/utils/helpers/JwtHelper';

import { AuthenticationProvider } from './Provider';
import { AuthProviderStorage } from './Storage';

export class VppnProvider extends AuthenticationProvider {
	private readonly token: string;
	private isTokenValid = false;
	private isTokenRefreshing = false;

	constructor(token: string) {
		super();
		this.token = token;

		if (isExpired(this.token)) {
			this.refreshToken();
		}
	}

	public getTokenAsync(): Promise<string> {
		// todo - check if token isExpired and refresh it immediately before returning, like in msal

		return Promise.resolve(this.token);
	}

	public isAuthenticated(): Promise<boolean> {
		return Promise.resolve(this.isTokenValid);
	}

	public isRefreshing(): boolean {
		return this.isTokenRefreshing;
	}

	public onAuthenticated(
		fn: (isAuth: boolean, message?: string) => void
	): void {
		super.onAuthenticated(fn);
		this.invokeOnAuthenticatedCallback();
	}

	protected refreshToken(): void {
		const { sub: userId = '' } = decode(this.token);
		const diff = getExpDateDiffInMs(this.token);

		console.log(
			`JWT will be refreshed in ~${Math.ceil(
				diff / 1000 / 60
			)} minutes (exactly in ${Math.round(
				diff / 1000
			)} seconds) on ${new Date(new Date().getTime() + diff).toLocaleString()}`
		);

		setTimeout(() => {
			this.isTokenRefreshing = true;
			console.log('JWT is refreshing...');

			refreshVppnToken(userId)
				.then((token) => {
					if (token && isExpired(token)) {
						AuthProviderStorage.getInstance().renew(
							new VppnProvider(token)
						);
					} else {
						this.isTokenValid = false;
						this.invokeOnAuthenticatedCallback();
					}
				})
				.catch(() => {
					this.isTokenValid = false;
					this.invokeOnAuthenticatedCallback();
				})
				.finally(() => {
					this.isTokenRefreshing = false;
				});
		}, diff);

		this.isTokenValid = true;
	}

	private invokeOnAuthenticatedCallback(): void {
		this.onAuthenticatedCallback(
			this.isTokenValid,
			this.isTokenValid ? 'JWT is correct' : 'Invalid JWT'
		);
	}
}
