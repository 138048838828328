import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Divider } from '@mui/material';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { unitStructureQuery } from 'api/queries/UnitQueries';
import { AreaName } from 'domain/constants';
import { isWarrantyStartedStatus } from 'domain/unit/Unit';
import { SectionTitleButtons } from 'library/components';
import { LegacyButton } from 'library/components/link/LegacyButton';
import { OperationType } from 'library/models/enums/OperationType';
import AuthorizeComponent from 'main/auth/AuthorizeComponent';
import { reactPlugin } from 'main/azureInsights/config';
import { useNumberParam } from 'modules/Shared/Components/Hooks/useNumberParam';
import { WarrantyDataGrid } from 'modules/Shared/Components/Warranty/WarrantyDataGrid';
import { WarrantyEmpty } from 'modules/Shared/Components/Warranty/WarrantyEmpty';

const WarrantySectionPage = () => {
	const id = useNumberParam('unitId');
	const { t } = useTranslation(['commonButton', 'warranty']);
	const { data } = useSuspenseQuery(unitStructureQuery(id));

	const isAvailable = useMemo(
		() => !!data && isWarrantyStartedStatus(data),
		[data]
	);

	return (
		<>
			{isAvailable && (
				<SectionTitleButtons>
					<AuthorizeComponent
						areaName={AreaName.MaintainCoverage}
						permission={OperationType.Create}>
						<LegacyButton
							label={t('commonButton:edit')}
							mvcAction={'UnitOverview/MaintainCoverage'}
							unitId={data.id}
						/>
					</AuthorizeComponent>
				</SectionTitleButtons>
			)}
			{isAvailable ? (
				<WarrantyDataGrid id={data.id} />
			) : (
				<>
					<Divider sx={{ my: 2 }} />
					<WarrantyEmpty />
				</>
			)}
		</>
	);
};

export default withAITracking(
	reactPlugin,
	WarrantySectionPage,
	'UnitOverviewWarrantySectionPage',
	'application-insight-container'
);
