import { Menu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/transitions/slide.css';

import IMenu from '../models/IMenu';
import { IMenuButton, renderMenuButton } from './menuFactory/MenuButton';
import { IMenuItemsRender } from './menuFactory/MenuItems';

export interface IMenuDropdownFactory {
	menu: IMenu;
	menuContainerClassNames?: string;
	menuClassNames?: string;
	menuAnimation?: boolean;
	menuBtn: IMenuButton;
	menuItems: IMenuItemsRender;
}

export const MenuDropdownFactory = ({
	menu,
	menuContainerClassNames,
	menuClassNames,
	menuAnimation,
	menuBtn,
	menuItems,
}: IMenuDropdownFactory): JSX.Element => {
	const isWithoutChildren = !menu.menuItems?.length;
	const getMenuContainerClassNames = () => {
		if (menuContainerClassNames === undefined) {
			if (isWithoutChildren) {
				return 'menu__dropdown menu__dropdown__without__children';
			}

			return 'menu__dropdown menu__dropdown__with__children';
		}

		return '';
	};

	const getMenuClassNames = () => {
		return menuClassNames === undefined
			? 'menu__dropdown__list'
			: menuClassNames;
	};

	const menuShouldBeAnimated =
		menuAnimation !== undefined ? menuAnimation : true;

	return (
		<li className={getMenuContainerClassNames()}>
			{isWithoutChildren ? (
				renderMenuButton({
					...menuBtn,
					menuLink: menu.route,
					containsChildren: !isWithoutChildren,
				})
			) : (
				<Menu
					menuClassName={getMenuClassNames}
					menuButton={renderMenuButton({
						...menuBtn,
						containsChildren: !isWithoutChildren,
					})}
					transition={menuShouldBeAnimated}>
					{menuItems.renderMenuItems({ menuItems: menu.menuItems })}
				</Menu>
			)}
		</li>
	);
};
