import * as React from 'react';

import { DisplayDevice } from '../../../../../library/models/enums/DisplayDevice';

export interface IExitActions {
	exit: () => void;
}

export interface IExitButton extends IExitActions {
	displayDevice: DisplayDevice;
}

const ExitButton = ({ exit, displayDevice }: IExitButton): JSX.Element => {
	const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		exit();
	};

	const baseClassName = 'header__nav__exit-btn';

	return (
		<button
			id={displayDevice}
			className={`${baseClassName} ${baseClassName}--${displayDevice}`}
			onClick={handleOnClick}>
			<i className={`${baseClassName}__icon`}></i>
		</button>
	);
};

export default React.memo(ExitButton);
