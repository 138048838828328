import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { PageButtons } from 'library/components/controls';
import { ApplicationInsightsTrackerException } from 'library/components/track/ApplicationInsightsTrackerException';
import { useAppSelector } from 'library/redux/hooks';
import { home_HomeUrl } from 'main/routes/urls/RouteUrls';
import { RootState } from 'store';

import { ErrorContent } from './ErrorContent';

const CRASH_REPORT_FILE_NAME = 'error.json';

export interface ErrorFallbackProps {
	error: Error;
}

export const useErrorUpload = ({ error }: ErrorFallbackProps) => {
	const { t } = useTranslation(['error']);

	const state = useAppSelector((state: RootState) => state);
	const { api } = useProductCenterApi();

	const { enqueueSnackbar } = useSnackbar();
	useEffect(() => {
		const reportError = {
			browser: window.navigator.userAgent,
			error: error.stack,
			location: window.location,
			state,
		};
		const file = new File(
			[JSON.stringify(reportError)],
			CRASH_REPORT_FILE_NAME,
			{
				type: 'application/json',
			}
		);

		api.uploadErrorFile({ file })
			.then(() => {
				enqueueSnackbar(t('error:application-crash-report-success'));
			})
			.catch(() => {
				enqueueSnackbar(t('error:application-crash-report-fail'), {
					variant: 'error',
				});
			});
		// run only once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export const ErrorFallback = (props: ErrorFallbackProps) => {
	const { t } = useTranslation(['error', 'home']);

	useErrorUpload(props);

	return (
		<ErrorContent
			description={t('error:contact-support')}
			subtitle={t('error:application-crash')}
			title={t('error:unauthenticated-title')}>
			<>
				<PageButtons
					primaryButtonLabel={t('home:root-title')}
					primaryButtonAction={() => {
						window.location.href = home_HomeUrl;
					}}
					withDivider={false}
				/>
				<ApplicationInsightsTrackerException
					metric={{ exception: props.error }}
				/>
			</>
		</ErrorContent>
	);
};
