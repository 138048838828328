import { FileCopy } from '@mui/icons-material';
import { Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { KitDto } from 'api/responses/models/SoftwareKit/KitDto';
import { TFunction } from 'i18next';
import {
	Card,
	CardField,
	CardFieldContainer,
	CardIconField,
} from 'library/components';
import { ItemProps } from 'library/components/infinityScroll/Item';
import { toPrecision } from 'library/formatters';
import { getLocalizedDateFormat } from 'library/formatters/DateFormatter';

export const renderPrice = (
	translation: TFunction<'unitOverview'>,
	price: number | null | undefined,
	currency: string | null
) => {
	if (price === null) {
		return translation('kit-price-na');
	}

	if (price === 0) {
		return translation('kit-price-free-of-charge');
	}

	return `${toPrecision(Number(price), 2)} ${currency}`;
};

export const renderDocuments = (docs: string[] | null) => {
	if (docs === null || docs.length === 0) {
		return <></>;
	}

	return (
		<>
			{docs.map((d) => {
				const parts = d.split('/');

				return (
					<Link key={d} href={d}>
						{parts[parts.length - 1]}
					</Link>
				);
			})}
		</>
	);
};

const Header = ({ item: { description, kitNumber } }: ItemProps<KitDto>) => {
	const { t } = useTranslation('unitOverview');

	return (
		<>
			<CardIconField
				header={t('kit-number-header')}
				Icon={<FileCopy />}
				isSingle
				value={kitNumber}
			/>
			<Typography
				variant="subtitle2"
				sx={{
					flex: 1,
				}}>
				{description}
			</Typography>
		</>
	);
};

const Body = ({
	item: { dateOf, driveLine, invoiceTo, performedBy, status },
}: ItemProps<KitDto>) => {
	const { t } = useTranslation('unitOverview');

	return (
		<CardFieldContainer>
			<CardField
				header={t('date-header')}
				sx={{ gridColumn: 1 }}
				value={getLocalizedDateFormat(dateOf)}
			/>
			<CardField
				header={t('status-header')}
				sx={{ gridColumn: 1 }}
				value={status}
			/>
			<CardField
				header={t('invoice-to-header')}
				sx={{ gridColumn: 1 }}
				value={invoiceTo}
			/>
			<CardField
				header={t('performed-by-header')}
				sx={{ gridColumn: 1 }}
				value={performedBy}
			/>
			<CardField
				header={t('driveline-header')}
				sx={{ gridColumn: 2, gridRow: 1 }}
				value={driveLine}
			/>
		</CardFieldContainer>
	);
};
const Footer = ({
	item: { currency, documents, function: fn, orderNumber, price },
}: ItemProps<KitDto>) => {
	const { t } = useTranslation('unitOverview');

	return (
		<CardFieldContainer>
			<CardField
				header={t('documents-header')}
				value={renderDocuments(documents)}
			/>
			<CardField header={t('order-header')} value={orderNumber} />
			<CardField
				header={t('suggested-retail-header')}
				value={renderPrice(t, price, currency)}
			/>
			<CardField header={t('function-header')} value={fn} />
		</CardFieldContainer>
	);
};

export const KitCard = ({ item }: ItemProps<KitDto>): JSX.Element => (
	<Card item={item} Header={Header} Body={Body} Footer={Footer} />
);
