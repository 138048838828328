import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useProductCenterApi } from 'api';
import { demoUsesQuery } from 'api/queries';
import { reactPlugin } from 'main/azureInsights/config';
import { useNumberParam } from 'modules/Shared/Components/Hooks/useNumberParam';
import { RegisterDemoDealerUsage } from 'modules/UnitOverview/DemoDealerUsage/RegisterDemoDealerUsage';
import { UnregisterDemoDealerUsage } from 'modules/UnitOverview/DemoDealerUsage/UnregisterDemoDealerUsage';

const DemoDealerUsageSectionPage = (): JSX.Element => {
	const unitId = useNumberParam();

	const { api } = useProductCenterApi();
	const { data: demoUsesData } = useSuspenseQuery({
		...demoUsesQuery(api, unitId),
	});

	return demoUsesData?.length ? (
		<RegisterDemoDealerUsage demo={demoUsesData[0]} />
	) : (
		<UnregisterDemoDealerUsage id={unitId} />
	);
};

export default withAITracking(
	reactPlugin,
	DemoDealerUsageSectionPage,
	'UnitOverviewDemoDealerUsageSectionPage',
	'application-insight-container'
);
