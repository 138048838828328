import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
	AccordionActionButtons,
	ActionDialogChildProps,
	ControlledTextField,
} from 'library/components/controls';
import { COMMENT_BOX_MAX_LENGTH } from 'library/constants/Validations';

import { mapFormToRequest } from './Mappers';
import { useReplaceFormSubmit } from './ReplaceFormSubmit';
import { IndividualReplaceProps } from './Types';

const replaceType = 'CorrectingError';

export const UndoMistakeReplaceForm = ({
	onConfirm,
	close,
	unitId,
	serialNumber,
}: IndividualReplaceProps & ActionDialogChildProps): JSX.Element => {
	const { t } = useTranslation(['common', 'unitOverview']);

	const { onSubmit, control, disableSubmit } = useReplaceFormSubmit({
		unitId,
		onConfirm,
		mapFormToRequest: mapFormToRequest(replaceType, serialNumber),
	});

	return (
		<form noValidate onSubmit={onSubmit}>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: '1fr',
					gap: 2,
					my: 2,
					pb: 2,
				}}>
				<ControlledTextField
					id="newSerialNumber"
					name="newSerialNumber"
					label={t('common:serial-number')}
					control={control}
					size="small"
					variant="standard"
					validation={{
						required: 'common:validation-error-message',
					}}
				/>
				<ControlledTextField
					id="replaceReason"
					control={control}
					size="small"
					name="replaceReason"
					label={t('unitOverview:replace-reason')}
					variant="standard"
					multiline
					validation={{
						required: 'common:validation-error-message',
						maxLength: {
							value: COMMENT_BOX_MAX_LENGTH,
							message:
								'commonWizard:validation-comment-box-max-length',
						},
					}}
				/>
			</Box>
			<AccordionActionButtons
				disableSubmit={disableSubmit}
				onClose={close}
			/>
		</form>
	);
};
