import { Box, Button, CircularProgress } from '@mui/material';
import { noop } from 'lodash';
import {
	KeyboardEvent,
	ReactElement,
	forwardRef,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';

import { keys } from 'library/keys';

import { CustomInputHandle, Input, InputProps } from '../../input';

export interface CustomInputWithButtonHandle extends CustomInputHandle {
	resetError: () => void;
}

export interface InputWithButtonProps extends InputProps {
	clearInputWhenSuccessful?: boolean;
	displayLoadingIndicatorDuringActionExecution?: boolean;
	displayLoadingIndicator?: boolean;
	icon: ReactElement;
	onButtonPressed: (input: string) => boolean | Promise<boolean>;
}

const getActionAsync = async (
	action: (input: string) => boolean | Promise<boolean>,
	param: string
) => (action instanceof Promise ? await action(param) : action(param));

const getIcon = (isLoading: boolean, icon: ReactElement) =>
	isLoading ? <CircularProgress size="2rem" color="inherit" /> : icon;

const getColor = (isError: boolean) => (isError ? 'error' : 'secondary');

export const InputWithButton = forwardRef<
	CustomInputWithButtonHandle,
	InputWithButtonProps
>(
	(
		{
			clearInputWhenSuccessful = true,
			displayLoadingIndicatorDuringActionExecution = false,
			displayLoadingIndicator = false,
			icon,
			label,
			onButtonPressed,
			onChange = noop,
		},
		ref
	): JSX.Element => {
		const inputRef = useRef<CustomInputHandle>(null);
		const wasInputChanged = useRef(false);
		const [errorState, setErrorState] = useState(false);
		const [loading, setLoading] = useState(false);

		const isLoading =
			displayLoadingIndicatorDuringActionExecution && loading;

		useImperativeHandle(
			ref,
			() => ({
				focus: inputRef.current?.focus ?? noop,
				getValue: inputRef.current
					? inputRef.current.getValue
					: () => '',
				reset: inputRef.current?.reset ?? noop,
				resetError: () => {
					setErrorState(false);
					wasInputChanged.current = true;
				},
			}),
			[inputRef]
		);

		const executeAction = async () => {
			if (!inputRef.current) {
				return;
			}
			const value = inputRef.current.getValue();

			if (errorState && !wasInputChanged.current) {
				onButtonPressed(value);
			}

			if (!(value && wasInputChanged.current && !isLoading)) {
				return;
			}

			wasInputChanged.current = false;

			if (await getActionAsync(onButtonPressed, value)) {
				if (clearInputWhenSuccessful) {
					inputRef.current.reset();
				}
			} else {
				setErrorState(true);
				inputRef.current.focus();
			}
		};

		const handleButtonClicked = () => {
			setLoading(true);

			return executeAction().finally(() => setLoading(false));
		};

		const handleKeyPressed = (event: KeyboardEvent<HTMLInputElement>) => {
			if (event.key === keys.ENTER) {
				event.preventDefault();
				handleButtonClicked();
			}
		};

		const handleOnChange = (value: string) => {
			wasInputChanged.current = true;

			if (errorState) {
				setErrorState(false);
			}
			onChange(value);
		};

		return (
			<Box
				data-testid={`input-with-button-box${
					errorState ? '-error' : ''
				}`}
				className="input-with-button__root"
				sx={{
					display: 'flex',
					width: '100%',

					'&>.input-with-button__button': {
						minWidth: (theme) => theme.spacing(7),
						padding: '6px',
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
						ml: -0.5,
					},
				}}>
				<Input
					className={'input-with-button__input'}
					isError={errorState}
					label={label}
					InputProps={{
						type: 'search',
					}}
					onChange={handleOnChange}
					onKeyPress={handleKeyPressed}
					ref={inputRef}
					sx={{
						minWidth: 7,
						'&>.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
							{
								borderWidth: '1px',
							},
					}}
				/>
				<Button
					data-testid="input-with-button-icon"
					className="input-with-button__button"
					variant="contained"
					color={getColor(errorState)}
					onClick={handleButtonClicked}
					disableElevation>
					{getIcon(displayLoadingIndicator || isLoading, icon)}
				</Button>
			</Box>
		);
	}
);
