import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import {
	ProductIndividualSearchDtoResult,
	UnitRegistrationModelGR,
	productCenterApi,
} from 'api';
import {
	DrivelineDto as DrivelineInstallationDto,
	InstallationDto as InstallationDomainDto,
	ProductIndividualDto as ProductIndividualDomainDto,
} from 'api/responses/models/UnitCreation/ProductIndividualSearchResultDto';
import { ProductIndividualSearchResult } from 'domain/productIndividual';
import { generateUUID } from 'library/utils/generateUuid';

import { ProductIndividualSearchNoResults } from './Types';

export const validateSearchProductResponse = (
	requestedSerialNumber: string,
	response: AxiosResponse<ProductIndividualSearchDtoResult, any>
): ProductIndividualSearchNoResults | null => {
	if (response.status === 204) {
		return {
			errorMessage: 'unitCreation:api-error-product-not-found',
			serialNumber: null,
		};
	}

	const result = response.data;

	if (
		result.responseType === 'InvalidIndividualStatus' &&
		(result.infoSource === 'PC' || !result.infoSource)
	) {
		return {
			errorMessage: 'unitCreation:api-error-product-status-invalid',
			serialNumber: requestedSerialNumber,
		};
	} else if (
		result.responseType === 'InvalidConfiguration' ||
		result.responseType === 'TemporaryDriveline'
	) {
		return {
			errorMessage:
				'unitCreation:api-error-product-invalid-configuration',
			serialNumber: null,
		};
	} else if (result.partialUnitStructure == null) {
		return {
			errorMessage: 'unitCreation:api-error-product-not-found',
			serialNumber: null,
		};
	}

	return null;
};

// TODO: remove after fully switch to GR #874908
// a mapper to support manual registration using the new GR endpoint
export const mapFromUnitRegistrationModelGRToProductIndividualSearchResult = (
	{
		evcConfigurationEmpty,
		infoSource,
		partialUnitStructure,
		responseType,
	}: UnitRegistrationModelGR,
	requestedSerialNumber: string
): ProductIndividualSearchResult => ({
	generatedUuid: generateUUID(),
	evcConfigurationEmpty,
	requestedSerialNumber,
	infoSource: infoSource!,
	partialUnitStructure: {
		installations:
			partialUnitStructure?.installations.map(
				(i): InstallationDomainDto => ({
					...i,
					sortPosition: 0, // does not exists in the response
					drivelines: i.drivelines.map(
						(d): DrivelineInstallationDto => ({
							...d,
							drivelinePosition: 0, // does not exists in the response
							productIndividuals: d.productIndividuals.map(
								(p): ProductIndividualDomainDto => ({
									...p,
									id: 0, // is not included in response
									generatedUuid: generateUUID(),
								})
							),
						})
					),
				})
			) || [],
	},
	responseType,
});

// TODO: remove after fully switch to GR #874908 - to support manual registration when GR is turned off by the environment variable - VITE_IS_GUIDED_REGISTRATION
export const getProductIndividuals = createAsyncThunk(
	'unit/getProductIndividuals',
	async (serialNumber: string, { rejectWithValue }) => {
		try {
			const response =
				await productCenterApi.searchProductIndividualBySerialNumber({
					serialNumber,
				});

			const validationError = validateSearchProductResponse(
				serialNumber,
				response
			);

			if (validationError) {
				return rejectWithValue(validationError);
			}

			return mapProductIndividualSearchResultApiToSliceType(
				response.data,
				serialNumber
			);
		} catch (_err) {
			return rejectWithValue({ errorMessage: 'common:error-unexpected' });
		}
	}
);

// TODO: remove after fully switch to GR #874908 - to support manual registration when GR is turned off by the environment variable - VITE_IS_GUIDED_REGISTRATION
const mapProductIndividualSearchResultApiToSliceType = (
	result: ProductIndividualSearchDtoResult,
	requestedSerialNumber: string
): ProductIndividualSearchResult => ({
	generatedUuid: generateUUID(),
	evcConfigurationEmpty: result.evcConfigurationEmpty,
	requestedSerialNumber: requestedSerialNumber,
	infoSource: result.infoSource!, // will exists, is checked before
	partialUnitStructure: {
		installations: result.partialUnitStructure!.installations.map(
			(i): InstallationDomainDto => ({
				...i,
				sortPosition: 0, // does not exists in the response
				drivelines: i.drivelines.map(
					(d): DrivelineInstallationDto => ({
						...d,
						drivelinePosition: 0, // does not exists in the response
						productIndividuals: d.productIndividuals.map(
							(p): ProductIndividualDomainDto => ({
								...p,
								id: 0, // is not included in response
								generatedUuid: generateUUID(),
							})
						),
					})
				),
			})
		),
	},
	responseType: result.responseType,
});
