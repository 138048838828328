import * as React from 'react';
import { Link } from 'react-router-dom';

export interface IMenuButton {
	menuLabel: string;
	hideBtn?: boolean;
	onMenuBtnClick?: (label: string) => void;
	onMenuLinkClick?: () => void;
	menuLink?: string;
	containsChildren?: boolean;
}

export const renderMenuButton = ({
	menuLabel,
	hideBtn,
	onMenuBtnClick,
	onMenuLinkClick,
	menuLink,
	containsChildren,
}: IMenuButton): JSX.Element => {
	const handleOnMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();

		if (containsChildren && onMenuBtnClick !== undefined) {
			onMenuBtnClick(e.currentTarget.value);
		}

		if (!containsChildren && onMenuLinkClick !== undefined) {
			onMenuLinkClick();
		}
	};

	const getBtnClassName = (): string =>
		`menu__dropdown__btn ${
			containsChildren
				? 'menu__dropdown__btn--sub-menu'
				: 'menu__dropdown__list'
		} ${hideBtn ? 'menu__dropdown__btn--hidden' : ''}`;

	return (
		<button
			onClick={handleOnMenuClick}
			aria-controls="fade-menu"
			aria-haspopup={containsChildren}
			value={menuLabel}
			className={getBtnClassName()}>
			{menuLink !== undefined ? (
				<Link to={menuLink}>{menuLabel}</Link>
			) : (
				menuLabel
			)}
		</button>
	);
};
