import { Box, Theme, useMediaQuery } from '@mui/material/';
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { userKeys } from 'api/queries';
import { Namespace } from 'i18next';
import { Area } from 'library/models/Area';
import { textOverflowSx } from 'library/utils/themes';
import { hasUserAccess } from 'main/auth/Authorization';

import { VerticalTabs } from './VerticalTabs';
import { ScrollableTabsProps } from './types';

const TabsStyled = styled(Tabs)(({ theme }) => ({
	background: theme.palette.background.default,
	padding: theme.spacing(1, 0),
	'& .MuiTab-root': {
		background: theme.palette.background.paper,
		margin: theme.spacing(0, 0.5),
	},
	[`& .${tabsClasses.scrollButtons}`]: {
		'&.Mui-disabled': { opacity: 0.2 },
	},
	'& .MuiTabItem-labelGroup': {
		minWidth: 0,
	},
	'& .MuiTabItem-subLabel': {
		fontSize: '0.65rem',
		textAlign: 'left',
		fontWeight: 'normal',
		...textOverflowSx,
	},
	'& .MuiTabItem-tag': {
		position: 'absolute',
		top: 5,
		right: 5,
		fontSize: '0.75rem',
		borderRadius: 12,
		lineHeight: theme.spacing(2),
		padding: '1px 6px',
		letterSpacing: 0.3,
		whiteSpace: 'nowrap',
	},
	'& .MuiTabItem-tag-error': {
		color: theme.palette.error.contrastText,
		background: theme.palette.error.main,
	},
	'& .MuiTabItem-tag-info': {
		color: theme.palette.info.contrastText,
		background: theme.palette.text.primary,
	},
}));

export const ScrollableTabs = memo(
	({ tabs: tabsConfiguration, paddingStyle }: ScrollableTabsProps) => {
		const { t } = useTranslation<Namespace>();

		const isMobile = useMediaQuery((theme: Theme) =>
			theme.breakpoints.only('xs')
		);

		// might be worth switching to search params
		const { pathname } = useLocation();
		const activeTab = tabsConfiguration.find(({ path }) =>
			pathname.includes(path)
		);
		const rootRef = useRef<HTMLElement | null>();

		const queryClient = useQueryClient();

		const availableAreas = queryClient.getQueryData<Area[]>(
			userKeys.securityMatrix()
		);

		const visibleTabs = tabsConfiguration
			.filter(
				(item) =>
					item.areaSecurity === undefined ||
					hasUserAccess(item.areaSecurity, availableAreas ?? [])
			)
			.map((tab) => ({ ...tab, title: t(tab.title) }));

		const setScrollPosition = () => {
			if (!rootRef.current) {
				return;
			}

			const boxRect = rootRef.current.getBoundingClientRect();

			if (window.innerHeight - boxRect.top < 200) {
				window.scrollBy({
					top: 250,
					behavior: 'smooth',
				});
			}
		};

		const sx = paddingStyle === 'tight' ? {} : { py: 3, px: 3 };

		return (
			<>
				{!isMobile && (
					<Box
						ref={rootRef}
						sx={(theme) => ({
							display: 'grid',
							mt: 4,
							mb: 3,
							[theme.breakpoints.only('xs')]: {
								mt: 0,
								mx: 0,
							},
						})}>
						<TabsStyled
							value={activeTab?.path || false}
							variant="scrollable"
							scrollButtons
							aria-label="visible arrows tabs"
							selectionFollowsFocus>
							{visibleTabs.map(
								({ badge, icon, path, subtitle, title }) => (
									<Tab
										sx={[
											sx,
											badge && paddingStyle !== 'tight'
												? { pr: 5 }
												: null,
										]}
										key={path}
										component={NavLink}
										to={path}
										value={path}
										onClick={setScrollPosition}
										label={
											<>
												<span>
													{title}
													{badge && (
														<span
															className={
																'MuiTabItem-tag MuiTabItem-tag-info'
															}>
															{badge}
														</span>
													)}
												</span>
												{subtitle && (
													<span
														className={
															'MuiTabItem-subLabel'
														}>
														{subtitle}
													</span>
												)}
											</>
										}
										iconPosition="top"
										icon={icon}
									/>
								)
							)}
						</TabsStyled>
					</Box>
				)}
				<VerticalTabs visibleTabs={visibleTabs} activeTab={activeTab} />
			</>
		);
	}
);
