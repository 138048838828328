import { Box } from '@mui/material';
import { HTMLAttributeAnchorTarget, PropsWithChildren } from 'react';
import { Link as RRDLink, To } from 'react-router-dom';

import { Sxable } from 'library/types';

export interface LinkProps extends PropsWithChildren<Sxable> {
	target?: HTMLAttributeAnchorTarget;
	to: To;
}

export const Link = ({
	children,
	className,
	sx = [],
	target,
	to,
}: LinkProps & { className?: string }): JSX.Element => (
	<Box
		className={className}
		component={RRDLink}
		to={to}
		sx={sx}
		target={target ?? '_blank'}>
		{children}
	</Box>
);
