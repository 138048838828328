import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';

import { Sxable } from 'library/types';

export interface SpinnerProps extends Sxable {
	size?: string;
}

export const Spinner: FC<SpinnerProps> = ({
	size = '5rem',
	sx = [],
}): JSX.Element => (
	<Box
		sx={[
			{
				display: 'flex',
				justifyContent: 'center',
			},
			{
				overflow: 'hidden',
				margin: (theme) => theme.spacing(10, 0, 0, 0),
			},
			...(Array.isArray(sx) ? sx : [sx]),
		]}>
		<CircularProgress size={size} />
	</Box>
);
