import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Edit, LocationOn as LocationOnIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { unitInfoQuery, unitStructureQuery } from 'api/queries';
import { AreaName } from 'domain/constants';
import { isRelocateEnabled } from 'domain/unit/Unit';
import { SectionButton, TextDisplay } from 'library/components';
import { SectionTitleButtons } from 'library/components/controls';
import { getRelocateToLink } from 'library/components/link';
import { OperationType } from 'library/models/enums/OperationType';
import AuthorizeComponent from 'main/auth/AuthorizeComponent';
import { reactPlugin } from 'main/azureInsights/config';
import { useNumberParam } from 'modules/Shared/Components/Hooks/useNumberParam';
import { LocationText } from 'modules/Shared/Components/OperatingLocation/LocationText';

import EditOperatingLocationPage from './EditOperatingLocationPage';

const OperatingLocationSectionPage = (): JSX.Element => {
	const unitId = useNumberParam('unitId');
	const { t } = useTranslation([
		'common',
		'commonUnit',
		'unitOverview',
		'productInOperation',
	]);
	const [unitLocationEdit, setUnitLocationEdit] = useState<boolean>(false);

	const { api } = useProductCenterApi();

	const queryStructure = useSuspenseQuery(unitStructureQuery(unitId));

	const { data: unitData } = useSuspenseQuery(unitInfoQuery(api, unitId));

	if (unitLocationEdit) {
		return (
			<EditOperatingLocationPage
				currentLocation={unitData.currentLocation}
				unitId={unitId}
				handleCancel={setUnitLocationEdit}
			/>
		);
	}

	return (
		<>
			<SectionTitleButtons>
				{isRelocateEnabled(queryStructure.data) && (
					<AuthorizeComponent
						areaName={AreaName.TransferUnit}
						permission={OperationType.Create}>
						<SectionButton
							text={t('commonUnit:button-relocate-to')}
							icon={<LocationOnIcon />}
							href={getRelocateToLink(unitData.id ?? 0)}
						/>
					</AuthorizeComponent>
				)}
				<AuthorizeComponent
					areaName={AreaName.Unit}
					permission={OperationType.Write}>
					<SectionButton
						text={t('unitOverview:edit-unit-location-title')}
						icon={<Edit />}
						onClick={() => setUnitLocationEdit(true)}
					/>
				</AuthorizeComponent>
			</SectionTitleButtons>
			<Box
				sx={{
					display: 'grid',

					gridTemplateColumns: {
						xs: 'repeat(1, 1fr)',
						sm: 'repeat(2, 1fr)',
						md: 'repeat(3, 1fr)',
					},

					gap: 2,
					p: 2,
				}}>
				<TextDisplay
					label={t('common:country')}
					value={
						<LocationText
							countryCode={unitData.currentLocation?.country}
						/>
					}
				/>
				<TextDisplay
					label={t('common:county')}
					value={unitData.currentLocation?.state}
				/>
				<TextDisplay
					label={t('common:city')}
					value={unitData.currentLocation?.city}
				/>
				<Box sx={{ gridColumn: { sm: 'span 3' } }}>
					<TextDisplay
						label={t('common:machine-location')}
						value={unitData.currentLocation?.machineVesselLocation}
					/>
				</Box>
				<TextDisplay
					label={t('common:zip-code')}
					value={unitData.currentLocation?.zipCode}
				/>
				<Box sx={{ gridColumn: { sm: 'span 2' } }}>
					<TextDisplay
						label={t('common:address')}
						value={unitData.currentLocation?.address}
					/>
				</Box>
			</Box>
		</>
	);
};

export default withAITracking(
	reactPlugin,
	OperatingLocationSectionPage,
	'UnitOverviewOperatingLocationSectionPage',
	'application-insight-container'
);
