import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Unit } from 'api/responses/models/Unit/UnitStructure/Unit';
import { TextDisplay } from 'library/components';

import { IdentifiersContainer } from '../Components/IdentifiersContainer';
import { LineOfBusinessDisplay } from '../Components/LineOfBusinessDisplay';
import { UnitPurposeDisplay } from '../Components/UnitPurposeDisplay';

export const InformationViewSection = ({ unitData }: { unitData: Unit }) => {
	const { t } = useTranslation(['unitCreation', 'commonUnit']);

	return (
		<IdentifiersContainer
			variant="bottomBorder"
			title={t('unitCreation:general-section-title')}
			subtitle={t('unitCreation:general-section-description')}>
			<Box
				sx={{
					gridColumn: { md: '2' },
					gridRow: { md: '2' },
				}}>
				<TextDisplay
					label={t('commonUnit:name')}
					value={unitData.identification?.name}
				/>
			</Box>
			<Box
				sx={{
					gridColumn: { md: '3' },
					gridRow: { md: '2' },
				}}>
				<TextDisplay
					label={t('commonUnit:registration-number')}
					value={unitData.identification?.registrationNumber}
				/>
			</Box>
			<Box
				sx={{
					gridColumn: { md: '2' },
					gridRow: { md: '3' },
				}}>
				<LineOfBusinessDisplay
					mainSegment={unitData.mainSegment}
					lineOfBusiness={unitData.consumerLineOfBusiness}
				/>
			</Box>
			<Box
				sx={{
					gridColumn: { md: '3' },
					gridRow: { md: '3' },
				}}>
				<UnitPurposeDisplay
					unitPurpose={unitData.consumerUsingPurpose}
				/>
			</Box>
		</IdentifiersContainer>
	);
};
