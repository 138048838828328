import { DeleteOutline, ReplayOutlined } from '@mui/icons-material';
import { Box, IconButton, LinearProgress, Typography } from '@mui/material';

import { FileDisplayBase, FileDisplayBaseProps } from './FileDisplayBase';
import { bytesToSize } from './functions';

export const ClientFileDisplay = ({
	size,
	completed,
	onDeleteClick,
	onRetryClick,
	percentCompleted,
	failed = false,
	...baseProps
}: {
	size: number;
	completed: boolean;
	percentCompleted?: number;
	onDeleteClick: () => void;
	onRetryClick?: () => void;
} & FileDisplayBaseProps) => {
	return (
		<Box>
			<FileDisplayBase {...baseProps} failed={failed}>
				<Typography
					sx={{
						flex: '1 0 auto',
						fontSize: 'small',
					}}>
					({bytesToSize(size)})
				</Typography>
				{onRetryClick instanceof Function && failed && (
					<IconButton
						onClick={() => {
							onRetryClick();
						}}>
						<ReplayOutlined />
					</IconButton>
				)}
				<IconButton
					onClick={() => {
						onDeleteClick();
					}}>
					<DeleteOutline />
				</IconButton>
				{!completed && !failed && (
					<Box
						sx={{
							position: 'absolute',
							height: 1,
							width: 1,
							bgcolor: 'rgba(255, 255, 255, 0.3)',
						}}></Box>
				)}
			</FileDisplayBase>
			{percentCompleted !== undefined && (
				<LinearProgress
					variant="determinate"
					color={failed ? 'error' : 'secondary'}
					value={percentCompleted}
				/>
			)}
		</Box>
	);
};
