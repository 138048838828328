import { CalendarToday } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FileSetDto } from 'api';
import { getUrlFileSetDownload } from 'api/ApiUrls';
import { TextDisplay } from 'library/components';
import { FileDisplayBase, getFileType } from 'library/components/controls';
import { getLocalizedDateFormat } from 'library/formatters';

export const DateCommentFilesDisplay = ({
	date,
	dateLabel,
	comment,
	fileSet,
}: {
	date: Date | null;
	dateLabel: string;
	comment: string | null;
	fileSet: FileSetDto | null | undefined;
}): JSX.Element => {
	const { t } = useTranslation(['commonWizard']);

	return (
		<>
			<Box
				sx={{
					display: 'grid',

					gridTemplateColumns: {
						xs: '1fr',
						sm: '1fr 2fr',
					},

					gap: { xs: 2, sm: 8 },
					mb: 4,
				}}>
				<TextDisplay
					label={dateLabel}
					value={getLocalizedDateFormat(date)}
					startIcon={<CalendarToday />}
				/>
				<TextDisplay
					label={t('commonWizard:additional-comment-label')}
					value={
						<Typography
							sx={{
								whiteSpace: 'pre-wrap',
							}}>
							{comment}
						</Typography>
					}
				/>
			</Box>
			{fileSet != null && (
				<Box
					sx={{
						display: 'grid',
						gap: 1,
						mb: 4,
					}}>
					{fileSet.files.map((file) => (
						<FileDisplayBase
							key={file.id}
							name={file.fileName}
							type={getFileType(file.fileName)}
							url={getUrlFileSetDownload(fileSet.id, file.id)}
							sx={{ gridColumn: 'span 2' }}
						/>
					))}
				</Box>
			)}
		</>
	);
};
