import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { PersonAddAlt1, ReportProblemOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { useProductCenterApi } from 'api';
import { unitConnectedCustomersQuery, unitStructureQuery } from 'api/queries';
import { unitKeys } from 'api/queries/UnitQueries';
import { EndCustomerDto } from 'api/responses/models/AddEndCustomer';
import { AreaName } from 'domain/constants';
import {
	isConsumerRegisterButtonDisabled,
	showEndConsumerSection,
} from 'domain/unit/Unit';
import { SectionButton } from 'library/components';
import { SectionTitleButtons } from 'library/components/controls';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import Authorize from 'main/auth/Authorize';
import AuthorizeComponent from 'main/auth/AuthorizeComponent';
import { reactPlugin } from 'main/azureInsights/config';
import {
	addEndCustomerPageWizardUrl,
	unitEditEndCustomerPageUrl,
} from 'main/routes/urls/RouteUrls';
import { EditCustomerDialog } from 'modules/EndCustomer/Shared/EditCustomerDialog';
import { useNumberParam } from 'modules/Shared/Components/Hooks/useNumberParam';
import { ConnectedCustomerCardDisplay } from 'modules/UnitOverview/Components/ConnectedCustomerCardDisplay';
import { LocalLinkButton } from 'modules/UnitOverview/Components/LocalLinkButton';

const EndCustomerSectionPage = (): JSX.Element => {
	const { t } = useTranslation(['unitOverview']);
	const unitId = useNumberParam();
	const [customerToEdit, setCustomerToEdit] = useState<EndCustomerDto | null>(
		null
	);

	const queryClient = useQueryClient();

	const { api } = useProductCenterApi();
	const queryResult = useSuspenseQuery(
		unitConnectedCustomersQuery(api, unitId)
	);

	const unitQueryResult = useSuspenseQuery({
		...unitStructureQuery(unitId),
	});

	const handleEditClose = (customer: EndCustomerDto | null) => {
		if (customer) {
			queryClient.invalidateQueries({
				queryKey: unitKeys.connectedCustomers(unitId),
			});
		}
		setCustomerToEdit(null);
	};

	const isCustomerRemovedDueToGDPR =
		queryResult.data.isRemovedByGdpr ?? false;

	const isRegistrationButtonDisabled = isConsumerRegisterButtonDisabled(
		unitQueryResult.data
	);

	const isConnected = showEndConsumerSection(unitQueryResult.data);

	return (
		<>
			{isConnected && (
				<SectionTitleButtons>
					<AuthorizeComponent
						areaName={AreaName.Unit}
						permission={OperationType.Create}>
						<SectionButton
							text={t('unitOverview:end-customer-register-new')}
							icon={<PersonAddAlt1 />}
							isLink
							to={generatePath(unitEditEndCustomerPageUrl, {
								unitId: unitId.toString(),
							})}
						/>
					</AuthorizeComponent>
				</SectionTitleButtons>
			)}
			{!isConnected && (
				<Authorize
					area={createArea(AreaName.Unit, OperationType.Create)}
					displayIntermediatePages={false}
					redirectWhenNotAuthorized={false}>
					<LocalLinkButton
						disabled={isRegistrationButtonDisabled}
						to={generatePath(addEndCustomerPageWizardUrl, {
							unitId: unitId.toString(),
						})}>
						{t('unitOverview:end-customer-register-new')}
					</LocalLinkButton>
				</Authorize>
			)}
			{isCustomerRemovedDueToGDPR ? (
				<Typography
					sx={{
						display: 'flex',
						gap: 1,
						alignItems: 'center',
					}}>
					<ReportProblemOutlined color="warning" />
					{t('unitOverview:end-customer-removed-due-to-gdpr')}
				</Typography>
			) : (
				isConnected && (
					<Box
						sx={{
							display: 'grid',

							gridTemplateColumns: {
								xs: '1fr',
								md: 'repeat(2, minmax(0, 1fr))',
							},

							gap: 2,
						}}>
						<ConnectedCustomerCardDisplay
							customer={queryResult.data.owner}
							changedByInfo={queryResult.data.ownerChangedByInfo}
							isOwner={true}
							onEditCustomer={setCustomerToEdit}
						/>
						<ConnectedCustomerCardDisplay
							customer={queryResult.data.unitManager}
							changedByInfo={
								queryResult.data.unitManagerChangedByInfo
							}
							isOwner={false}
							onEditCustomer={setCustomerToEdit}
						/>
					</Box>
				)
			)}
			{customerToEdit != null && (
				<EditCustomerDialog
					customerToEdit={customerToEdit}
					onClose={handleEditClose}
				/>
			)}
		</>
	);
};

export default withAITracking(
	reactPlugin,
	EndCustomerSectionPage,
	'UnitOverviewEndCustomerSectionPage',
	'application-insight-container'
);
