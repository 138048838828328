import {
	Box,
	Button,
	ButtonProps,
	CircularProgress,
	Divider,
	Theme,
	useMediaQuery,
} from '@mui/material';
import { noop } from 'lodash';
import { ReactNode, useRef } from 'react';
import useDoubleClick from 'use-double-click';

export interface PageButtonsProps {
	primaryButtonLabel?: string;
	primaryButtonAction?: () => void;
	isPrimaryButtonSubmitType?: boolean;
	displayLoadingIndicator?: boolean;
	primaryButtonDisabled?: boolean;
	primaryButtonFormId?: string;
	secondaryButtonLabel?: string;
	secondaryButtonAction?: () => void;
	secondaryButtonProps?: ButtonProps;
	withDivider?: boolean;
	endIconButton?: ReactNode;
}

export const PageButtons = ({
	primaryButtonLabel,
	primaryButtonAction = noop,
	isPrimaryButtonSubmitType = false,
	displayLoadingIndicator = false,
	primaryButtonDisabled = false,
	primaryButtonFormId,
	secondaryButtonLabel,
	secondaryButtonAction = noop,
	secondaryButtonProps,
	withDivider = true,
	endIconButton = undefined,
}: PageButtonsProps): JSX.Element => {
	const buttonRef = useRef<HTMLButtonElement>(null);
	useDoubleClick({
		onSingleClick: primaryButtonAction,
		onDoubleClick: noop,
		ref: buttonRef,
		latency: 200,
	});

	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.only('xs')
	);

	const buttonSize = isMobile ? 'medium' : 'large';

	return (
		<>
			{withDivider && <Divider></Divider>}
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: secondaryButtonLabel
						? 'flex-end'
						: 'center',
					pt: 4,
				}}>
				{secondaryButtonLabel && (
					<Button
						variant="outlined"
						color="secondary"
						size={buttonSize}
						onClick={secondaryButtonAction}
						endIcon={endIconButton}
						sx={{ mr: 'auto' }}
						{...secondaryButtonProps}>
						{secondaryButtonLabel}
					</Button>
				)}
				<Button
					disabled={displayLoadingIndicator || primaryButtonDisabled}
					variant="contained"
					color="secondary"
					size={buttonSize}
					type={isPrimaryButtonSubmitType ? 'submit' : undefined}
					form={
						isPrimaryButtonSubmitType
							? primaryButtonFormId
							: undefined
					}
					endIcon={
						<>
							{!displayLoadingIndicator && endIconButton}
							{displayLoadingIndicator && (
								<CircularProgress size="1rem" color="inherit" />
							)}
						</>
					}
					ref={buttonRef}
					sx={{
						visibility: primaryButtonLabel ? 'visible' : 'hidden',
					}}>
					{primaryButtonLabel}
				</Button>
			</Box>
		</>
	);
};
