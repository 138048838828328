import { useEffect } from 'react';
import {
	UseControllerProps,
	UseFormReturn,
	UseFormTrigger,
	useFormState,
	useWatch,
} from 'react-hook-form';

import { IdLabelDto } from 'api/responses/models/Common/IdLabelDto';
import {
	callSignPattern,
	hullIdIso10087Pattern,
	hullIdUsCoastGuardPattern,
	imoNumberPattern,
	mmsiNumberPattern,
} from 'domain/unit/validationPatterns';
import { UnitDataFormTypes } from 'modules/Shared/State/unit';

export function validateDimensions(key: keyof UnitDataFormTypes) {
	return {
		validate: (value: string, formValues: UnitDataFormTypes) =>
			!!formValues[key] && !value
				? 'unitCreation:unit-information-measureUnit-required'
				: true,
	};
}

export const brandFieldValidation = {
	required: 'unitCreation:unit-information-measureUnit-required',
};

export const modelFieldValidation = {
	required: 'unitCreation:unit-information-measureUnit-required',
};

export const iso10087FieldValidation = {
	pattern: {
		value: hullIdIso10087Pattern,
		message: 'common:validation-message-valid-identifier',
	},
};

export const usCoastGuardFieldValidation = {
	pattern: {
		value: hullIdUsCoastGuardPattern,
		message: 'common:validation-message-valid-identifier',
	},
};

export const imoNumberFieldValidation = {
	pattern: {
		value: imoNumberPattern,
		message: 'common:validation-message-valid-identifier',
	},
};

export const mmsiNumberFieldValidation = {
	pattern: {
		value: mmsiNumberPattern,
		message: 'common:validation-message-valid-identifier',
	},
};

export const callSignFieldValidation = {
	pattern: {
		value: callSignPattern,
		message: 'common:validation-message-valid-identifier',
	},
};

type UnitDataFormKeys = keyof UnitDataFormTypes;

const sectionFieldsNames: Readonly<UnitDataFormKeys[]> = [
	'hullId',
	'mmsiNumber',
	'imoNumber',
	'callSign',
] as const;

export interface MarineIdentifiersSectionValidationHookProps {
	control: UseFormReturn<UnitDataFormTypes>['control'];
	trigger: UseFormTrigger<UnitDataFormTypes>;
}

export const useMarineIdentifiersSectionValidation = ({
	control,
	trigger,
}: MarineIdentifiersSectionValidationHookProps): boolean => {
	const { isSubmitted } = useFormState({
		control,
		name: sectionFieldsNames,
	});

	const sectionWatch = useWatch({
		control,
		name: sectionFieldsNames,
	});

	const anyValueEntered = sectionWatch.some((value) => Boolean(value));
	const isValidSection = !isSubmitted || anyValueEntered;

	useEffect(() => {
		// only trigger validation, after form was submitted at least once
		if (isSubmitted) {
			trigger(sectionFieldsNames);
		}
		// trigger validation on any change on variable - anyValueEntered
	}, [trigger, isSubmitted, anyValueEntered]);

	return isValidSection;
};

export const validateMarineIdentifiersSection = (
	additionalValidation?: Omit<
		UseControllerProps<UnitDataFormTypes>['rules'],
		'validate'
	>
) => ({
	...additionalValidation,
	validate: (
		_: string | IdLabelDto | null | undefined,
		formValues: UnitDataFormTypes
	) => {
		// iterate over the keys of identifiers
		for (const key of sectionFieldsNames) {
			if (formValues[key] == null) {
				continue;
			}

			// at least one must have value
			if (
				typeof formValues[key] === 'string' &&
				formValues[key].toString().length > 0
			) {
				return true;
			}
		}

		return false;
	},
});
