import { Box, Divider, Typography, TypographyProps } from '@mui/material';
import { PropsWithChildren } from 'react';

type SectionTitleProps = {
	title: string;
	titleVariant?: TypographyProps['variant'];
	withDivider?: boolean;
};

export const SectionTitle = ({
	title,
	titleVariant = 'h5',
	children,
	withDivider = true,
}: PropsWithChildren<SectionTitleProps>): JSX.Element => (
	<>
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				minHeight: 40,
				gap: 1,
				mb: withDivider ? 0 : 2,
			}}>
			<Typography
				variant={titleVariant}
				sx={{
					flex: 1,
				}}>
				{title}
			</Typography>
			{children}
		</Box>
		{withDivider && <Divider sx={{ my: 2 }} />}
	</>
);
