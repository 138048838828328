import { ExpandMore } from '@mui/icons-material';
import { AccordionProps, AccordionSummaryProps } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

export const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion
		disableGutters
		elevation={0}
		square
		slotProps={{ heading: { component: 'h4' } }} // by default should be h3, but header styles are not yet migrated
		{...props}
	/>
))(
	({
		theme: {
			spacing,
			palette: {
				gray: { main },
			},
		},
	}) => ({
		border: 0,
		paddingTop: spacing(2),
		borderBottom: `1px solid black`,
		'&.Mui-expanded': {
			background: main,
		},
		'&:before': {
			display: 'none',
		},
	})
);

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary expandIcon={<ExpandMore />} {...props} />
))(() => ({
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(180deg)',
		backgroundColor: 'transparent',
	},
}));

export const AccordionDetails = styled(MuiAccordionDetails)(
	({
		theme: {
			spacing,
			palette: {
				gray: { main },
			},
		},
	}) => ({
		padding: spacing(2),
		background: main,
	})
);
