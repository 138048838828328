import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store';

import { FileUploadState } from './Types';

// Use this thunk to reset file state, it also clears up files from browser memory
export const createResetStateThunk = (
	thunkName: string,
	stateSelector: (s: RootState) => FileUploadState
) =>
	createAsyncThunk(thunkName, (_, { getState }) => {
		const state = stateSelector(getState() as RootState);

		for (const fileData of state.filesUploaded) {
			URL.revokeObjectURL(fileData.fileUrl);
		}

		return true;
	});
