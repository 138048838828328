import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { unitIsTelematicQuery } from 'api/queries/UnitQueries';
import { reactPlugin } from 'main/azureInsights/config';
import { useNumberParam } from 'modules/Shared/Components/Hooks/useNumberParam';
import { LegacySection } from 'modules/UnitOverview/Components/LegacySection';

const TelematicsSectionPage = (): JSX.Element => {
	const { t } = useTranslation(['unitOverview']);
	const { api } = useProductCenterApi();

	const unitId = useNumberParam();

	const unitQuery = useSuspenseQuery(unitIsTelematicQuery(api, unitId));

	return (
		<LegacySection
			unitId={unitId}
			mvcAction={'UnitOverview/TelematicsOverview'}
			buttonLabel={t('unitOverview:telematics-view-services')}
			buttonDisabled={!unitQuery.data}
		/>
	);
};

export default withAITracking(
	reactPlugin,
	TelematicsSectionPage,
	'UnitOverviewTelematicsSectionPage',
	'application-insight-container'
);
