import { Box, Paper, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { brandsQuery, modelsQuery } from 'api/queries/InstallationQueries';
import { IdLabelDto } from 'api/responses/models/Common/IdLabelDto';
import { convertWeightToTonnes } from 'api/responses/models/Unit/UnitMeasurement';
import { MainSegment } from 'api/responses/models/Unit/UnitStructure/MainSegment';
import {
	customBrandKey,
	customModelKey,
	isCustomBrand,
	isCustomModel,
} from 'domain/unit/brandAndModel';
import {
	ControlledAutocomplete,
	ControlledTextField,
} from 'library/components/controls';
import { isUnitMarine } from 'modules/Shared/State/unit';

import { brandFieldValidation, modelFieldValidation } from '../Validations';
import { FormSectionProps } from './Types';

const getColumnSpan = (isShort: boolean) =>
	isShort
		? { xs: 'span 16', sm: 'span 8', md: 'span 3' }
		: { xs: 'span 16', sm: 'span 16', md: 'span 6' };

const shouldBeDisplayed = (display: boolean) => (display ? undefined : 'none');

export const BrandModelSection = ({
	control,
	dirtyFields: { brand: isBrandDirty, model: isModelDirty },
	mainSegment,
	resetField,
	setValue,
}: Required<FormSectionProps> & { mainSegment: MainSegment | null }) => {
	const { t } = useTranslation(['unitCreation', 'commonUnit']);
	const { enqueueSnackbar } = useSnackbar();

	const brandValue = useWatch({ control, name: 'brand' });
	const modelValue = useWatch({ control, name: 'model' });
	const customBrandValue = useWatch({ control, name: 'customBrand' });
	const customModelValue = useWatch({ control, name: 'customModel' });

	const initialBrand = useRef(brandValue);
	const initialModel = useRef(modelValue);

	const isCustomBrandVal = isCustomBrand(brandValue?.id);
	const isCustomModelVal = useRef(false);
	isCustomModelVal.current = isCustomModel(modelValue?.id);

	const customModelOption: IdLabelDto = useMemo(
		() => ({
			id: customModelKey,
			label: t('unitCreation:unit-information-custom-model-option-label'),
		}),
		[t]
	);

	const customBrandOption: IdLabelDto = useMemo(
		() => ({
			id: customBrandKey,
			label: t('unitCreation:unit-information-custom-brand-option-label'),
		}),
		[t]
	);

	const { api } = useProductCenterApi();
	const { data: brandsData, isLoading: brandsIsLoading } = useQuery({
		...brandsQuery(api, mainSegment as MainSegment),
		enabled: mainSegment !== null,
	});

	const { data: modelsData, isLoading: modelsIsLoading } = useQuery({
		...modelsQuery(api, brandValue ? parseInt(brandValue.id) : 0),
		enabled: brandValue?.id !== null && !isCustomBrandVal,
	});

	// setup correct brand based on label name
	useEffect(() => {
		if (!brandsIsLoading && brandsData) {
			const brand = brandsData.find(
				({ label }) => label === customBrandValue
			);

			if (brand) {
				initialBrand.current = brand;
				setValue('brand', brand);
			}
		}
	}, [brandsData, brandsIsLoading, customBrandValue, setValue]);

	// setup correct model based on label name
	useEffect(() => {
		if (initialBrand && !modelsIsLoading && modelsData) {
			const model = modelsData.find(
				({ label }) => label === customModelValue
			);

			if (model) {
				setValue('model', model);
			}
		}
	}, [
		control,
		customModelValue,
		initialBrand,
		modelsData,
		modelsIsLoading,
		setValue,
	]);

	useEffect(() => {
		if (
			initialBrand.current?.label !== customBrandValue &&
			initialBrand.current?.id !== brandValue?.id
		) {
			resetField('model', { defaultValue: null });

			if (
				isUnitMarine(mainSegment) &&
				brandValue &&
				!modelsData &&
				!modelsIsLoading
			) {
				resetAllDimensions();
				enqueueSnackbar(t('commonUnit:unavailable-dimensions'), {
					variant: 'warning',
				});
			}
		}
	}, [brandValue, initialBrand, modelsData, modelsIsLoading]);

	const resetLength = useCallback(() => {
		resetField('length', { defaultValue: '' });
		resetField('lengthUnit', { defaultValue: '' });
	}, [resetField]);

	const resetWidth = useCallback(() => {
		resetField('width', { defaultValue: '' });
		resetField('widthUnit', { defaultValue: '' });
	}, [resetField]);

	const resetWeight = useCallback(() => {
		resetField('displacement', { defaultValue: '' });
		resetField('displacementUnit', { defaultValue: '' });
	}, [resetField]);

	const resetSizeDimensions = useCallback(() => {
		resetLength();
		resetWidth();
	}, [resetLength, resetWidth]);

	const resetAllDimensions = useCallback(() => {
		resetSizeDimensions();
		resetWeight();
	}, [resetSizeDimensions, resetWeight]);

	useEffect(() => {
		if (!isUnitMarine(mainSegment) || (!isBrandDirty && !isModelDirty)) {
			return;
		}

		if (
			!modelsData ||
			!modelValue ||
			!modelsData.find(({ id }) => modelValue.id === id)
		) {
			resetAllDimensions();
			return;
		}

		if (
			initialModel.current?.id !== modelValue?.id &&
			!isCustomBrand(modelValue?.id)
		) {
			resetField('customModel', { defaultValue: null });
			isCustomModelVal.current = false;
		}

		const model = modelsData.find(({ id }) => modelValue?.id === id);

		if (model && model.dimension) {
			const {
				dimension: { length, weight, width, unitOfSize, unitOfWeight },
			} = model;

			setValue('dimensionsType', 'Actual');
			if (unitOfSize) {
				if (length) {
					setValue('length', length.toString());
					setValue('lengthUnit', unitOfSize);
				} else {
					resetLength();
				}
				if (width) {
					setValue('width', width.toString());
					setValue('widthUnit', unitOfSize);
				} else {
					resetWidth();
				}
			} else {
				resetSizeDimensions();
			}

			const {
				weight: convertedWeight,
				unitOfWeight: convertedUnitOfWeight,
			} = convertWeightToTonnes(weight, unitOfWeight);

			if (convertedWeight && convertedUnitOfWeight) {
				setValue('displacement', convertedWeight.toString());
				setValue('displacementUnit', convertedUnitOfWeight);
			} else {
				resetWeight();
			}
		} else {
			resetAllDimensions();
			enqueueSnackbar(t('commonUnit:unavailable-dimensions'), {
				variant: 'warning',
			});
		}
	}, [modelValue, modelsData]);

	return (
		<Box
			component={Paper}
			variant="flatGrey"
			sx={{
				display: 'grid',
				gridTemplateColumns: 'repeat(16, 1fr)',
				gap: 2,
				p: 2,
			}}>
			<Typography
				variant="h5"
				sx={{
					gridColumn: { xs: 'span 16', md: 'span 4' },
				}}>
				{t('unitCreation:unit-information-brand-model-title')}
			</Typography>
			<ControlledAutocomplete
				id="brand-select"
				name="brand"
				control={control}
				label={t('commonUnit:brand')}
				loading={brandsIsLoading}
				options={[...(brandsData || []), customBrandOption]}
				size="small"
				sx={{ gridColumn: getColumnSpan(isCustomBrandVal) }}
				validation={brandFieldValidation}
				getOptionId={(option: any) => option}
			/>
			{shouldBeDisplayed(!isCustomBrandVal) ? (
				<ControlledTextField
					id="custom-brand-field"
					name="customBrand"
					label={t(
						'unitCreation:unit-information-custom-brand-input'
					)}
					control={control}
					size="small"
					fullWidth
					sx={{
						gridColumn: {
							xs: 'span 16',
							sm: 'span 8',
							md: 'span 3',
						},
					}}
					validation={brandFieldValidation}
				/>
			) : (
				<></>
			)}
			<ControlledAutocomplete
				id="model-select"
				name="model"
				control={control}
				label={t('commonUnit:model')}
				loading={modelsIsLoading}
				options={[...(modelsData || []), customModelOption]}
				size="small"
				sx={{ gridColumn: getColumnSpan(isCustomModelVal.current) }}
				validation={modelFieldValidation}
				getOptionId={(option: any) => option}
			/>
			{shouldBeDisplayed(!isCustomModelVal.current) ? (
				<ControlledTextField
					id="custom-model-field"
					name="customModel"
					label={t(
						'unitCreation:unit-information-custom-model-input'
					)}
					control={control}
					size="small"
					fullWidth
					sx={{
						gridColumn: {
							xs: 'span 16',
							sm: 'span 8',
							md: 'span 3',
						},
					}}
					validation={modelFieldValidation}
				/>
			) : (
				<></>
			)}
		</Box>
	);
};
