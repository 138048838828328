import { Box, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SwapIndividualInDrivelinesCommand, useProductCenterApi } from 'api';
import { ParseKeys } from 'i18next';
import {
	AccordionActionButtons,
	ActionDialogChildProps,
	ControlledSelect,
	ControlledTextField,
} from 'library/components/controls';
import { COMMENT_BOX_MAX_LENGTH } from 'library/constants/Validations';
import { useValidationErrorsSnackbar } from 'library/hooks/useValidationErrorsSnackbar';
import { useCheckConsoleDebuggingReadAccess } from 'main/auth/checkUserAccess';

import { IndividualReplaceProps, SwapIndividualFormType } from './Types';

export const SwapReplaceForm = ({
	onConfirm,
	close,
	matchingIndividualsToSwap,
	productCategory,
	serialNumber,
	unitId,
}: IndividualReplaceProps & ActionDialogChildProps): JSX.Element => {
	const { t } = useTranslation(['unitOverview', 'productType']);
	const { api } = useProductCenterApi();

	const mapFormToRequest: (
		data: SwapIndividualFormType
	) => SwapIndividualInDrivelinesCommand = (data) => {
		return {
			sourceSerialNumber: serialNumber.trim(),
			targetSerialNumber: data.targetSerialNumber.trim(),
		};
	};

	const { enqueueSnackbar } = useSnackbar();
	const { enqueueValidationSnackbar } = useValidationErrorsSnackbar();
	const { checkConsoleDebuggingReadAccess } =
		useCheckConsoleDebuggingReadAccess();

	const { handleSubmit, control } = useForm<SwapIndividualFormType>({
		mode: 'onBlur',
	});

	const swapMutation = useMutation<
		boolean,
		unknown,
		SwapIndividualInDrivelinesCommand
	>({
		mutationFn: async (data) => {
			return (await api.swapIndividual(unitId, data)).data;
		},
		onSuccess: () => {
			enqueueSnackbar(t(`unitOverview:swap-success`), {
				variant: 'success',
			});

			onConfirm();
		},
	});

	const submitForm = async (data: SwapIndividualFormType): Promise<void> => {
		try {
			await swapMutation.mutateAsync(mapFormToRequest(data));
		} catch (error) {
			checkConsoleDebuggingReadAccess() && console.error(error);
			enqueueValidationSnackbar(error as Error);
		}
	};

	const onSubmit = handleSubmit(async (data) => {
		await submitForm(data);
	});

	const options = (matchingIndividualsToSwap ?? []).map((val) => {
		return { id: val, label: `${productCategory} ${val}` };
	});

	return (
		<form noValidate onSubmit={onSubmit}>
			<Typography variant="body2">
				{t('unitOverview:swap-individual-with', {
					productCategory: t(
						`productType:${productCategory}` as ParseKeys<
							['unitOverview', 'productType']
						>
					),
					serialNumber: serialNumber,
				})}
			</Typography>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: '1fr',
					gap: 2,
					my: 2,
					pb: 2,
				}}>
				<ControlledSelect
					options={options}
					control={control}
					name={'targetSerialNumber'}
					id={'targetSerialNumber'}
					label={t('unitOverview:target-serial-number')}
					variant={'standard'}
					size="small"
				/>
				<ControlledTextField
					id="replaceReason"
					control={control}
					size="small"
					name="replaceReason"
					label={t('unitOverview:replace-reason')}
					variant="standard"
					multiline
					validation={{
						required: 'common:validation-error-message',
						maxLength: {
							value: COMMENT_BOX_MAX_LENGTH,
							message:
								'commonWizard:validation-comment-box-max-length',
						},
					}}
				/>
			</Box>
			<AccordionActionButtons
				disableSubmit={swapMutation.isPending}
				onClose={close}
			/>
		</form>
	);
};
