import { Box, SvgIcon, Typography } from '@mui/material';
import { head, reduce, tail } from 'lodash';

import IconUserAdmin from 'assets/images/main/icon_user_admin.svg?react';
import IconUserDealer from 'assets/images/main/icon_user_dealer.svg?react';
import { DisplayDevice } from 'library/models/enums/DisplayDevice';
import { selectUser } from 'library/redux/application';
import { useAppSelector } from 'library/redux/hooks';

type UserInfoProps = {
	isOpen?: boolean;
	displayDevice?: DisplayDevice;
};

const EXCLUDED_BALDO_USER_ROLE = '29135_User';

const UserInfo = ({ isOpen, displayDevice }: UserInfoProps) => {
	const { isExternal, name, roles } = useAppSelector(selectUser);

	const isMobile =
		displayDevice === DisplayDevice.Tablet ||
		displayDevice === DisplayDevice.Mobile;

	const userRolesToDisplay = (roles ?? []).filter(
		(role) => role.description != EXCLUDED_BALDO_USER_ROLE
	);

	return (displayDevice && isOpen) ||
		(displayDevice === undefined && isOpen === undefined) ? (
		<Box
			className={
				'header__nav__info-wrapper ' +
				(isMobile ? 'header__nav__mobile-info-wrapper' : '')
			}
			id={
				isMobile
					? `header__nav__mobile-info-wrapper--${displayDevice}`
					: ''
			}>
			<Box
				sx={{
					mr: 2,
					textAlign: 'right',
				}}>
				<Typography
					variant="subtitle1"
					sx={{
						fontSize: '1em',
						lineHeight: '1.2em',
						fontWeight: 'normal',
						overflowWrap: 'anywhere',
					}}>
					{name}
				</Typography>
				<Typography
					variant="subtitle1"
					sx={{
						fontSize: '0.72em',
						lineHeight: '1.2em',
						fontWeight: 'lighter',
					}}>
					{reduce(
						tail(userRolesToDisplay) ?? [],
						(acc, r) => {
							return `${acc}, ${r.description}`;
						},
						head(userRolesToDisplay)?.description
					)}
				</Typography>
			</Box>
			<SvgIcon component={isExternal ? IconUserAdmin : IconUserDealer} />
		</Box>
	) : (
		<> </>
	);
};

export default UserInfo;
