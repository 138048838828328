import { lazy } from 'react';
import { Outlet, Route } from 'react-router-dom';

import { AreaName } from 'domain/constants';
import { OperationType } from 'library/models/enums/OperationType';
import { createArea } from 'library/utils/helpers/AreaHelper';
import Authorize from 'main/auth/Authorize';

const OrderInfoPage = lazy(() => import('pages/orderinfo/OrderInfoPage'));

export const getOrderInfoRoutes = (): JSX.Element[] => [
	<Route
		key={'OrderInfo'}
		path={''}
		element={
			<Authorize
				area={createArea(AreaName.Individual, OperationType.Read)}>
				<Outlet />
			</Authorize>
		}>
		<Route index element={<OrderInfoPage />} />
	</Route>,
];
