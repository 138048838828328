import { CountryInfoDto } from 'api';

import { IdLabelDto } from '../Common/IdLabelDto';

export const mapCountryInfoToIdLabelCountry = (
	countryInfo: CountryInfoDto
): IdLabelDto => ({
	id: countryInfo.alpha2 as string,
	label: countryInfo.translatedCountryName as string,
});

export const mapCountryInfoToIdLabelPhonePrefix = (
	countryInfo: CountryInfoDto
): IdLabelDto => ({
	id: countryInfo.alpha2 as string,
	label: `+${countryInfo.dialCode} ${countryInfo.translatedCountryName}`,
});
