import { Box, Typography, useTheme } from '@mui/material';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { CommentDto } from 'api';
import { Comment } from 'library/components';
import { Sxable } from 'library/types';

interface ProductIndividualCommentsListProps extends Sxable {
	comments?: CommentDto[];
	isAdding: boolean;
}

export const ProductIndividualCommentsList = ({
	comments,
	isAdding,
	sx,
}: ProductIndividualCommentsListProps) => {
	const { t } = useTranslation(['unitOverview']);
	const {
		palette: { grey },
		spacing,
	} = useTheme();

	return (
		<Box
			sx={{
				borderTop: `1px solid ${grey[400]}`,
				borderBottom: `1px solid ${grey[400]}`,
			}}>
			{!!comments?.length ? (
				<>
					{orderBy(comments, ['modificationDate'], 'desc').map(
						(comment, index) => (
							<Comment
								key={`comment-${comment.id}-${
									comment.modifiedBy
								}-${comment.modificationDate.getTime()}`}
								comment={comment}
								isAdded={!index && isAdding}
								sx={sx}
							/>
						)
					)}
				</>
			) : (
				<Typography sx={{ textAlign: 'center', padding: spacing(4) }}>
					{t('unitOverview:product-individual-comment-dialog-empty')}
				</Typography>
			)}
		</Box>
	);
};
