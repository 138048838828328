import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { Button, ButtonGroup, Input, styled } from '@mui/material';
import { ChangeEvent } from 'react';

export const DEFAULT_MAX_VALUE = 999;

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
	'& .MuiButtonGroup-grouped': {
		minWidth: theme.spacing(4.5),
	},
	'& .MuiButtonGroup-grouped:not(:last-of-type)': {
		border: 'none',
	},
}));

const StyledInput = styled(Input)({
	borderTop: '1px solid',
	borderBottom: '1px solid',
	borderColor: 'secondary.main',
	'&:hover, &:active': {
		borderColor: 'secondary.dark',
	},
	'& input': {
		textAlign: 'center',
	},
});

interface PlusMinusInputProps {
	disabled?: boolean;
	max?: number;
	onChange: (value: number) => void;
	unsigned?: boolean;
	value: number;
}

export const PlusMinusInput = ({
	disabled = false,
	onChange,
	max = DEFAULT_MAX_VALUE,
	unsigned = false,
	value,
}: PlusMinusInputProps): JSX.Element => {
	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (!event.target.validity.valid) {
			event.target.value = value.toString();
			return;
		}

		event.stopPropagation();
		const newValue = parseInt(event.target.value, 10);
		onChange(newValue);
	};

	const handleAddButton = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		if (value < max) {
			onChange(value + 1);
		}
	};

	const handleRemoveButton = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		onChange(value - 1);
	};

	return (
		<StyledButtonGroup size="small" variant="contained" color="secondary">
			<Button
				aria-label="reduce"
				onClick={handleRemoveButton}
				disabled={disabled || (unsigned && value === 0)}>
				<RemoveIcon fontSize="small" />
			</Button>
			<StyledInput
				inputProps={{
					type: 'text',
					pattern: '[0-9]*',
					inputMode: 'numeric',
					maxLength: Number(max).toString().length,
				}}
				size="small"
				color="secondary"
				value={value}
				onChange={handleInputChange}
				disableUnderline
				disabled={disabled}
			/>
			<Button
				aria-label="increase"
				onClick={handleAddButton}
				disabled={disabled || value === max}>
				<AddIcon fontSize="small" />
			</Button>
		</StyledButtonGroup>
	);
};
