import { isEmpty, sortBy } from 'lodash';
import { DateTime } from 'luxon';

import {
	FinalizeOfflineServiceProtocolCommand,
	GenerateServiceProtocolData,
	GetServiceProtocolInitialDatesDto,
	PerformedOperationRequest,
	SaveOnlineServiceProtocolDraftCommand,
	ServiceActivityEventDto,
	ServiceActivityEventsInfoDto,
	ServiceProtocolDto,
	ServiceProtocolStatus,
} from 'api';
import { ServiceProtocolActivitiesQueryParams } from 'api/queries/ServiceProtocolQueries';
import { ChassisId } from 'domain/driveline';
import { serviceProtocolStatusToTranslationMapping } from 'domain/serviceProtocol';
import { ParseKeys, TOptions } from 'i18next';
import {
	fromIsoToDate,
	fromIsoToLuxonDate,
	fromJsDateToLuxonDate,
	isValidDate,
} from 'library/formatters';
import { FileUploadState } from 'library/redux/fileUpload';
import { isBlob } from 'library/utils/helpers/FileHelper';

import {
	ActivitiesFormType,
	ActivityFormType,
	ActivityType,
	ServiceActivityFormType,
	ServiceActivityType,
	ServiceInfoStepFormType,
	ServiceInfoStepStateType,
} from '../Types';
import { GenerateServiceProtocolSlice } from './Types';

export const mapFromFormToState = ({
	lastServiceDate,
	productInOperationDate,
	serviceDate,
	...formRest
}: ServiceInfoStepFormType): ServiceInfoStepStateType => ({
	...formRest,
	lastServiceDate: lastServiceDate?.toJSON() || '',
	productInOperationDate: productInOperationDate?.toJSON() || '',
	serviceDate: serviceDate?.toJSON() || '',
});

export const mapFromStateToForm = (
	state: ServiceInfoStepStateType
): ServiceInfoStepFormType =>
	isEmpty(state)
		? ({} as ServiceInfoStepFormType)
		: {
				...state,
				lastServiceDate: state.lastServiceDate
					? fromIsoToLuxonDate(state.lastServiceDate)
					: null,
				productInOperationDate: fromIsoToLuxonDate(
					state.productInOperationDate
				),
				serviceDate: fromIsoToLuxonDate(state.serviceDate),
			};

export const mapFromInitialDatesToForm = (
	data: GetServiceProtocolInitialDatesDto,
	language: string | null,
	serviceDate: string | null
): ServiceInfoStepFormType => ({
	lastServiceOperatingHours: data.lastProtocolRunningHours,
	lastServiceDate: fromJsDateToLuxonDate(data.lastServiceDate),
	operatingHours: data.currentEngineRunningHours,
	productInOperationDate: fromJsDateToLuxonDate(data.productInOperation),
	serviceDate: isValidDate(serviceDate)
		? fromIsoToLuxonDate(serviceDate as string)
		: DateTime.utc().set({
				hour: 0,
				minute: 0,
				second: 0,
				millisecond: 0,
			}),
	language,
});

export const mapFromStateToQueryParams = (
	{
		language,
		lastServiceDate,
		lastServiceOperatingHours,
		operatingHours,
		productInOperationDate,
		serviceDate,
	}: ServiceInfoStepStateType,
	chassisId: ChassisId
): ServiceProtocolActivitiesQueryParams => ({
	...chassisId,
	currentEngineHours: operatingHours ?? 0,
	preferredLanguage: language ?? '',
	productInOperationDate: fromIsoToDate(productInOperationDate),
	serviceDate: fromIsoToDate(serviceDate),
	lastServiceDate: lastServiceDate ? fromIsoToDate(lastServiceDate) : null,
	lastServiceEngineHours: lastServiceOperatingHours,
});

export const mapGenerateServiceProtocolData = (
	serviceInfo: ServiceInfoStepStateType
): GenerateServiceProtocolData => ({
	preferredLanguage: serviceInfo.language ?? '',
	serviceDate: new Date(serviceInfo.serviceDate),
	productInOperationDate: new Date(serviceInfo.productInOperationDate),
	currentEngineHours: serviceInfo.operatingHours ?? 0,
	lastServiceEngineHours: serviceInfo.lastServiceOperatingHours ?? null,
	lastServiceDate: serviceInfo.lastServiceDate
		? new Date(serviceInfo.lastServiceDate)
		: null,
});

export const mapDtoToServiceInfo = (
	serviceProtocol: ServiceProtocolDto
): ServiceInfoStepStateType => ({
	serviceDate: serviceProtocol.serviceDate?.toISOString() || '',
	lastServiceDate: serviceProtocol.lastServiceDate?.toISOString() || '',
	productInOperationDate:
		serviceProtocol.productInOperation?.toISOString() || '',
	language: 'en-US', // TODO: add mapping when API model is updated
	lastServiceOperatingHours: serviceProtocol.lastServiceEngineHours,
	operatingHours: serviceProtocol.currentEngineHours,
});

const mapServiceActivities = (
	data: ActivitiesFormType
): PerformedOperationRequest[] => {
	const serviceActivityKeys = Object.keys(data.activities);
	const performedOperations: SaveOnlineServiceProtocolDraftCommand['performedOperations'] =
		[];

	for (const activityId of serviceActivityKeys) {
		const serviceActivityId = parseInt(activityId);
		const activityServices = data.activities[serviceActivityId].services;
		const servicesKeys = Object.keys(
			data.activities[serviceActivityId].services
		);

		for (const servicesKey of servicesKeys) {
			const phraseId = parseInt(servicesKey);

			const { comment, imageId, isPerformed, isProposal, photo } =
				activityServices[phraseId];

			const isNewPhoto = photo && !isBlob(photo); // if it's a blog, that means the photo hasn't changed

			performedOperations.push({
				serviceActivityId,
				phraseId,
				comment: comment ?? null,
				isPerformed: isPerformed ?? null,
				isProposal: isProposal ?? null,
				photo: isNewPhoto ? photo : null,
				deletedPhotoId:
					(!photo || isNewPhoto) && imageId ? imageId : null, // SP had an image with ID, but it has been deleted by user (form is empty) and he might provide a new one
			});
		}
	}

	return performedOperations;
};

export const mapFromStateToRequest = (
	data: GenerateServiceProtocolSlice,
	fileState: FileUploadState
): FinalizeOfflineServiceProtocolCommand => ({
	drivelineId: data.drivelineId,
	serviceProtocolId: data.serviceProtocolId,
	generateServiceProtocolData: mapGenerateServiceProtocolData(
		data.serviceInfo
	),
	location: data.location,
	comment: data.offlineActivities.additionalComment,
	customProtocolName: data.offlineActivities.serviceName,
	folderName: fileState.attachmentFolderName,
	offlineProtocolFileName:
		fileState.filesUploaded.find(({ isAdditional }) => !isAdditional)
			?.serverName || '',
	uploadedFiles: fileState.filesUploaded
		.filter(({ isAdditional }) => isAdditional)
		.map(({ serverName }) => serverName),
});

export const mapFromActivitiesTypeToDraftRequest = (
	drivelineId: number,
	activities: ActivitiesFormType
): SaveOnlineServiceProtocolDraftCommand => ({
	drivelineId,
	performedOperations: mapServiceActivities(activities),
	usedNumberOfHours: parseFloat(activities.usedHours),
});

export const mapQueryResponseToType = (
	dto?: ServiceActivityEventsInfoDto | null
): ActivityType[] =>
	!dto
		? []
		: dto.serviceActivityEvents.map(
				({
					description,
					engineHours,
					estimatedHours,
					isPeriodic,
					months,
					operations,
					recommended,
					serviceActivityId,
					sortOrder,
				}) => ({
					estimation: estimatedHours,
					header: {
						description: description || '',
						hours: engineHours || 0,
						isPeriodic: isPeriodic || false,
						months,
					},
					services: sortBy(
						operations.map(
							({
								description,
								isEmissionRelated,
								lastPerformed,
								phraseId,
								sortOrder,
								task,
							}) => ({
								action: task.type,
								taskDescription: task.description,
								id: phraseId,
								isEmissionRelated,
								lastServiceDate: lastPerformed,
								name: description,
								sortOrder,
							})
						) as ServiceActivityType[],
						({ sortOrder }) => sortOrder
					),
					id: serviceActivityId,
					isRecommended: recommended,
					sortOrder,
				})
			);

export const mapActivitiesDtoToFormType = (
	dtos: ServiceActivityEventDto[]
): ActivityFormType[] => {
	const activitiesForm: ActivityFormType[] = [];

	dtos.forEach(({ serviceActivityId, operations }) => {
		const servicesForm: ServiceActivityFormType[] = [];
		operations.forEach(
			({ comment, isProposed, imageId, phraseId, status }) => {
				servicesForm[phraseId] = {
					comment,
					isPerformed: status === 'Performed',
					isProposal: isProposed,
					imageId,
				} as ServiceActivityFormType;
			}
		),
			(activitiesForm[serviceActivityId] = {
				services: servicesForm,
			});
	});

	return activitiesForm;
};
export const getServiceProtocolStatusToTranslationMapping = (
	status: ServiceProtocolStatus | null
): ParseKeys<['serviceProtocol'], TOptions, 'serviceProtocol'> =>
	status
		? serviceProtocolStatusToTranslationMapping[status]
		: 'serviceProtocol:status-not-saved';
