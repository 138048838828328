// Home
export const home_HomeUrl = '/';

// Search
export const searchAreaUrl = '/search';
export const search_endCustomerUrl = `${searchAreaUrl}/consumer`;

// Register
export const registerAreaUrl = '/register';
export const register_endCustomerUrl = `${registerAreaUrl}/consumer`;
export const register_unitUrl = `${registerAreaUrl}/unit`;

// Developers
const developersAreaUrl = '/developers';
export const developers_componentsUrl = `${developersAreaUrl}/components`;
export const developers_typographyPageUrl = `${developersAreaUrl}/typography`;
export const developers_DeploymentAlertUrl = `${developersAreaUrl}/managedeploymentalert`;
export const developers_publicationCachePageUrl = `${developersAreaUrl}/publicationcache`;

// Errors
const errorsAreaUrl = `/errors`;
/** /PC/errors/authentication */
export const errors_AuthenticationUrl = `${errorsAreaUrl}/authentication`;
/** /PC/errors/authorization */
export const errors_AuthorizationUrl = `${errorsAreaUrl}/authorization`;

// Not Found
export const notFoundUrl = '/notfound';

// Maintenance planner
export const maintenancePlannerUrl = '/MaintenancePlanner';

//Unit
export const unitOverViewAreaUrl = '/unitoverview';
export const unitAreaUrl = '/unit';
export const unitRelativeUrl = ':unitId';

export const unitAddEndCustomer = 'addendcustomer';
export const unitProductInOperation = 'productinoperation';
export const unitCommissioning = 'commissioning';
export const unitEdit = 'edit';
export const unitEditEndCustomer = 'endcustomer';
export const unitEditEndCustomerConnect = 'connect';
export const unitAddEndCustomerPageUrl = `${unitAreaUrl}/${unitAddEndCustomer}`;
export const unitProductInOperationPageUrl = `${unitAreaUrl}/${unitProductInOperation}`;
export const unitCommissioningPageUrl = `${unitAreaUrl}/${unitCommissioning}`;
export const addEndCustomerPageWizardUrl = `${unitAddEndCustomerPageUrl}/${unitRelativeUrl}`;
export const productInOperationWizardPageUrl = `${unitProductInOperationPageUrl}/${unitRelativeUrl}`;
export const commissioningWizardPageUrl = `${unitCommissioningPageUrl}/${unitRelativeUrl}`;
export const unitEditPageUrl = `${unitAreaUrl}/${unitEdit}/${unitRelativeUrl}`;
export const unitEditEndCustomerPageUrl = `${unitAreaUrl}/${unitEditEndCustomer}/${unitRelativeUrl}/${unitEditEndCustomerConnect}`;

// Unit Overview
export const UnitOverviewPageUrl = `${unitOverViewAreaUrl}/${unitRelativeUrl}`;
export const UnitOverview_Configuration = 'configuration';
export const UnitOverview_DemoDealerUsage = 'demodealerusage';
export const UnitOverview_Diagnostic = 'diagnostic';
export const UnitOverview_Telematics = 'telematics';
export const UnitOverview_EndCustomer = 'endcustomer';
export const UnitOverview_EndCustomer_Url = `${UnitOverviewPageUrl}/${UnitOverview_EndCustomer}`;
export const UnitOverview_Information = 'information';
export const UnitOverview_Kits = 'kits';
export const UnitOverview_OperatingLocation = 'operatinglocation';
export const UnitOverview_ServiceProtocols = 'service-protocols';
export const UnitOverview_OtherProducts = 'otherproducts';
export const UnitOverview_ServiceAgreements = 'serviceagreements';
export const UnitOverview_Variants = 'variants';
export const UnitOverview_Warranty = 'warranty';
export const unitOverviewEventLog = 'eventLog';
export const UnitOverview_EventLog_Url = `${UnitOverviewPageUrl}/${unitOverviewEventLog}`;

export const UnitOverview_Information_Edit = `${UnitOverview_Information}/edit`;

// Driveline view
export const drivelineviewAreaUrl = '/drivelineview';
export const drivelineviewParam = ':id';
export const drivelineviewPageUrl = `${drivelineviewAreaUrl}/${drivelineviewParam}`;
export const drivelineviewWarranty = 'warranty';
export const drivelineviewVariants = 'variants';
export const drivelineviewComponentsAndSoftware = 'componentsandsoftware';
export const drivelineviewSoftwareParameters = 'softwareparameters';
export const drivelineviewProtocols = 'serviceprotocols';

// Product Individual view
export const productIndividualviewAreaUrl = '/productindividualview';
export const productIndividualviewSNParam = ':serialnumber';
export const productIndividualSNParamName = 'serialnumber';
export const productIndividualviewSectionParam = ':section';
export const productIndividualviewPageUrl = `${productIndividualviewAreaUrl}/${productIndividualviewSNParam}/${productIndividualviewSectionParam}`;
export const productIndividualTechnical = 'technical';
export const productIndividualCampaigns = 'campaigns';
export const productIndividualClaims = 'claims';
export const productIndividualParts = 'parts';
export const productIndividualConfiguration = 'configuration';
export const productIndividualEventLog = 'eventlog';
export const productIndividualLogisticTrail = 'logistictrail';
export const productIndividualVstCodes = 'vstcodes';
export const productIndividualDocuments = 'documents';
export const productIndividualRepairs = 'repairs';
export const productIndividualPolicy = 'policy';
export const productIndividualEdit = `${productIndividualviewAreaUrl}/edit/${productIndividualviewSNParam}`;

// Order Info view
export const orderInfoArea = 'orderinfo';
export const orderInfoParam = ':id';
export const orderInfoPageUrl = `${orderInfoArea}/${orderInfoParam}`;

export const UNIT_ROUTES = [
	unitAddEndCustomer,
	unitProductInOperation,
	unitCommissioning,
	unitEdit,
	unitEditEndCustomer,
];

// Service Protocol
// Example route /serviceprotocol/34/generate&drivelineId=2232455
export const serviceProtocolArea = 'serviceprotocol';
export const serviceProtocolParam = ':serviceProtocolId';
export const generateServiceProtocolUrl = `${serviceProtocolArea}/${serviceProtocolParam}?/generate`;
export const serviceProtocolView = `${serviceProtocolArea}/${serviceProtocolParam}/view`;
