import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { isProdEnv } from 'library/constants';
import { aiKey } from 'main/auth/config';

// initialize AI
export const reactPlugin = new ReactPlugin();

if (isProdEnv) {
	const appInsights = new ApplicationInsights({
		config: {
			instrumentationKey: aiKey,
			enableAutoRouteTracking: true,
			extensions: [reactPlugin],
		},
	});

	const adjustPageName = (loggedUri: string) => {
		// all logged Uri should be lower case
		loggedUri = loggedUri.toLowerCase();

		// product individual page should have separate treatment, as the serial number could be text
		if (loggedUri.toLowerCase().includes('/productindividualview/')) {
			// try to replace if there is some subpage first
			if (loggedUri.match(/productindividualview\/(\w+?)\//)) {
				return loggedUri.replace(
					/productindividualview\/(\w+?)\//,
					'productindividualview/{id}/'
				);
			}

			// last resort if it is only product individual page
			return loggedUri.replace(
				/productindividualview\/(\w+)/,
				'productindividualview/{id}'
			);
		}

		// Handle rest of the cases
		return loggedUri
			.replace(/\/\d+?\//, '/{id}/') // in the middle
			.replace(/\/\d+$/, '/{id}'); // at the end
	};

	appInsights.addTelemetryInitializer((envelope) => {
		try {
			const telemetryItem = envelope.baseData;

			// To check the telemetry item’s type:
			if (envelope.ext?.trace.name != null) {
				envelope.ext.trace.name = adjustPageName(
					envelope.ext.trace.name
				);
			}
			if (
				telemetryItem != null &&
				(envelope.baseType === 'PageviewData' ||
					envelope.baseType === 'PageviewPerformanceData')
			) {
				// Do not track developers pages
				if (
					telemetryItem.uri.toLowerCase().indexOf('developers') !== -1
				)
					return false;

				telemetryItem.refUri = telemetryItem.uri = adjustPageName(
					telemetryItem.uri
				);
			} else if (
				telemetryItem != null &&
				envelope.baseType === 'RemoteDependencyData'
			) {
				telemetryItem.name = adjustPageName(telemetryItem.name);
			}
		} catch (ex) {
			console.warn(
				'Azure Insight: Something went wrong in processing urls.'
			);
			console.warn(ex);
		}

		return true;
	});

	appInsights.loadAppInsights();
}
