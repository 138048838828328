import { Button, ButtonProps } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

export const LocalLinkButton = ({
	to,
	children,
	...buttonProps
}: React.PropsWithChildren<{
	to: LinkProps['to'];
	disabled?: ButtonProps['disabled'];
}>): JSX.Element => (
	<Button
		component={Link}
		variant="contained"
		size="large"
		color="secondary"
		to={to}
		{...buttonProps}>
		{children}
	</Button>
);
