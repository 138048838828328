import { createEntityAdapter } from '@reduxjs/toolkit';

import {
	GuidedRegistrationState,
	ProductIndividualMatchPosition,
} from './Types';

export const matchesToProcessList = createEntityAdapter<
	ProductIndividualMatchPosition,
	string
>({
	selectId: ({ idTemporary }) => idTemporary,
});

export const matchesToProcessListSelectors =
	matchesToProcessList.getSelectors<GuidedRegistrationState>(
		({ matchesToProcess }) => matchesToProcess
	);
