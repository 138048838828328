// file generated automatically by pipeline. Custom changes will be lost. Generator project is located - https://dev.azure.com/VolvoGroup-MASDCL/ProductCenter/_git/Tools
import boatEnUS from './enUS/boat.json';
import campaignEnUS from './enUS/campaign.json';
import commissioningEnUS from './enUS/commissioning.json';
import commonEnUS from './enUS/common.json';
import commonButtonEnUS from './enUS/commonButton.json';
import commonStatusEnUS from './enUS/commonStatus.json';
import commonUnitEnUS from './enUS/commonUnit.json';
import commonWizardEnUS from './enUS/commonWizard.json';
import componentEnUS from './enUS/component.json';
import componentInformationPlateEnUS from './enUS/componentInformationPlate.json';
import connectedUnitsEnUS from './enUS/connectedUnits.json';
import customerEnUS from './enUS/customer.json';
import deploymentAlertEnUS from './enUS/deploymentAlert.json';
import drivelineviewEnUS from './enUS/drivelineview.json';
import errorEnUS from './enUS/error.json';
import eventLogEnUS from './enUS/eventLog.json';
import fileUploadEnUS from './enUS/fileUpload.json';
import homeEnUS from './enUS/home.json';
import languageEnUS from './enUS/language.json';
import layoutEnUS from './enUS/layout.json';
import maintenancePlannerEnUS from './enUS/maintenancePlanner.json';
import menuEnUS from './enUS/menu.json';
import orderInfoEnUS from './enUS/orderInfo.json';
import productInOperationEnUS from './enUS/productInOperation.json';
import productIndividualEnUS from './enUS/productIndividual.json';
import productTypeEnUS from './enUS/productType.json';
import publicationCacheEnUS from './enUS/publicationCache.json';
import searchProductEnUS from './enUS/searchProduct.json';
import serviceProtocolEnUS from './enUS/serviceProtocol.json';
import unitCreationEnUS from './enUS/unitCreation.json';
import unitEditEnUS from './enUS/unitEdit.json';
import unitOverviewEnUS from './enUS/unitOverview.json';
import warrantyEnUS from './enUS/warranty.json';

export const resources = {
	enUS: {
		boat: boatEnUS,
		campaign: campaignEnUS,
		commissioning: commissioningEnUS,
		common: commonEnUS,
		commonButton: commonButtonEnUS,
		commonStatus: commonStatusEnUS,
		commonUnit: commonUnitEnUS,
		commonWizard: commonWizardEnUS,
		component: componentEnUS,
		componentInformationPlate: componentInformationPlateEnUS,
		connectedUnits: connectedUnitsEnUS,
		customer: customerEnUS,
		deploymentAlert: deploymentAlertEnUS,
		drivelineview: drivelineviewEnUS,
		error: errorEnUS,
		eventLog: eventLogEnUS,
		fileUpload: fileUploadEnUS,
		home: homeEnUS,
		language: languageEnUS,
		layout: layoutEnUS,
		maintenancePlanner: maintenancePlannerEnUS,
		menu: menuEnUS,
		orderInfo: orderInfoEnUS,
		productIndividual: productIndividualEnUS,
		productInOperation: productInOperationEnUS,
		productType: productTypeEnUS,
		publicationCache: publicationCacheEnUS,
		serviceProtocol: serviceProtocolEnUS,
		unitCreation: unitCreationEnUS,
		unitEdit: unitEditEnUS,
		unitOverview: unitOverviewEnUS,
		warranty: warrantyEnUS,
		searchProduct: searchProductEnUS,
	},
} as const;

export const namespaces = [
	'boat',
	'campaign',
	'commissioning',
	'common',
	'commonButton',
	'commonStatus',
	'commonUnit',
	'commonWizard',
	'component',
	'componentInformationPlate',
	'connectedUnits',
	'customer',
	'deploymentAlert',
	'drivelineview',
	'error',
	'eventLog',
	'fileUpload',
	'home',
	'language',
	'layout',
	'maintenancePlanner',
	'menu',
	'orderInfo',
	'productIndividual',
	'productInOperation',
	'productType',
	'publicationCache',
	'serviceProtocol',
	'unitCreation',
	'unitEdit',
	'unitOverview',
	'warranty',
	'searchProduct',
];
