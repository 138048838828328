import { Comment } from '@mui/icons-material';
import { Box, InputAdornment, TextField } from '@mui/material';
import { KeyboardEvent, useCallback, useRef, useState } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';

import {
	ControlledTextField,
	TextFieldElementProps,
} from './ControlledTextField';

export type ControlledCommentBoxProps<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<
	TextFieldElementProps<TFieldValues, TName>,
	| 'multiline'
	| 'fullWidth'
	| 'maxRows'
	| 'onClick'
	| 'onBlur'
	| 'onFocus'
	| 'className'
>;

export const ControlledCommentBox = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	sx = [],
	slotProps = {},
	...rest
}: ControlledCommentBoxProps<TFieldValues, TName>): JSX.Element => {
	const [isExpanded, setIsExpanded] = useState(true);
	const boxRef = useRef<HTMLDivElement>();

	const expandCommentBox = useCallback(() => {
		setIsExpanded(true);
	}, []);

	const hideCommentBox = useCallback(() => {
		setIsExpanded(false);
	}, []);

	const { input, ...restSlotProps } = slotProps;

	return (
		<Box
			ref={boxRef}
			className="controlled-comment-box__root"
			sx={[
				{
					position: 'relative',
				},
				...(Array.isArray(sx) ? sx : [sx]),
			]}>
			<TextField size={rest.size} sx={{ zIndex: 0 }} disabled={true} />
			<ControlledTextField
				fullWidth
				multiline
				maxRows={isExpanded ? undefined : 1}
				className={
					'controlled-comment-box__text-field ' + isExpanded
						? 'expanded'
						: ''
				}
				onKeyDown={stopPropagation}
				onClick={expandCommentBox}
				onBlur={hideCommentBox}
				onFocus={expandCommentBox}
				slotProps={{
					input: {
						endAdornment: (
							<InputAdornment position="end">
								<Comment />
							</InputAdornment>
						),
						...input,
					},
					...restSlotProps,
				}}
				sx={{
					position: 'absolute',
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					zIndex: 1,

					'& textarea:first-of-type': {
						overflow: 'hidden',
						transition: 'height 0.2s ease',
					},

					'& .MuiInputBase-root': {
						bgcolor: 'background.paper',
					},

					'&.expanded': {
						zIndex: 15,
					},
				}}
				{...rest}></ControlledTextField>
		</Box>
	);
};

function stopPropagation(e: KeyboardEvent<HTMLDivElement>) {
	// seems like position absolute is causing buttons on the same form to catch the event first,
	// we need to stop propagation, so that comment text box will get the event
	e.stopPropagation();
}
