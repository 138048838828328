import { Link, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { GridActionsCellItemProps } from '@mui/x-data-grid-pro';
import { HTMLAttributeAnchorTarget, ReactNode, forwardRef } from 'react';

export const LinkActionItem = forwardRef<
	HTMLLIElement | null,
	Pick<GridActionsCellItemProps, 'showInMenu' | 'label'> & {
		href: string;
		startIcon: ReactNode;
		target?: HTMLAttributeAnchorTarget | undefined;
	}
>(({ href, label, target, startIcon }, ref): JSX.Element => {
	return (
		<MenuItem ref={ref} sx={{ padding: 0 }}>
			<Link
				underline="hover"
				href={href}
				target={target}
				sx={{
					flex: '1 1 auto',
					display: 'flex',
					alignItems: 'center',
					padding: (theme) => theme.spacing(0.75, 2),
				}}>
				<ListItemIcon>{startIcon}</ListItemIcon>
				<ListItemText>{label}</ListItemText>
			</Link>
		</MenuItem>
	);
});

LinkActionItem.displayName = 'LinkActionItem';
