import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { DimensionsType } from 'api/responses/models/Unit/UnitMeasurement';
import {
	ApiStatus,
	UnitConfigurationType,
	UnitDataFormTypes,
} from 'modules/Shared/State/unit/Types';

import { matchesToProcessList } from './Entity';
import { searchIndividual } from './Thunks';
import {
	GuidedRegistrationState,
	ProductIndividualMatchPosition,
} from './Types';

export const DEFAULT_DIMENSIONS_TYPE: DimensionsType = 'Estimated';

export const initialState: GuidedRegistrationState = {
	activeStep: 0,
	status: ApiStatus.Idle,
	type: UnitConfigurationType.CREATION,
	unitData: {
		hullIdStandard: null,
		dimensionsType: DEFAULT_DIMENSIONS_TYPE,
		length: '',
		lengthUnit: '',
		width: '',
		widthUnit: '',
		displacement: '',
		displacementUnit: '',
		lineOfBusiness: '',
		unitPurpose: '',
	},
	unitMetadata: {
		lineOfBusiness: undefined,
		lineOfBusinessDescription: undefined,
		unitPurpose: undefined,
		unitPurposeDescription: undefined,
		mainSegment: 'M', // TODO: remove when GR wizard is done
	},
	matchesToProcess: matchesToProcessList.getInitialState(),
};

export const guidedRegistrationSlice = createSlice({
	name: 'guidedRegistration',
	initialState,
	reducers: {
		addMatchToProcess: (
			state,
			{ payload }: PayloadAction<ProductIndividualMatchPosition>
		) => {
			const selector = matchesToProcessList.getSelectors();
			const match = payload.partNumber
				? selector.selectById(
						state.matchesToProcess,
						payload.idTemporary
					)
				: undefined;

			if (match) {
				matchesToProcessList.updateOne(state.matchesToProcess, {
					id: match.idTemporary,
					changes: payload,
				});
			} else {
				matchesToProcessList.addOne(state.matchesToProcess, payload);
			}
		},
		nextStep: (state) => {
			state.activeStep += 1;
		},
		previousStep: (state) => {
			if (state.activeStep === 0) {
				return;
			}

			state.activeStep -= 1;
		},
		setIsAdded: (state) => {
			if (state.model) {
				state.model.isAdded = true;
			}
		},
		updateFormData: (
			state,
			{ payload }: PayloadAction<UnitDataFormTypes>
		) => {
			state.unitData = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(searchIndividual.pending, (state) => {
				state.status = ApiStatus.Pending;
			})
			.addCase(
				searchIndividual.fulfilled,
				(state, { payload: { gr } }) => {
					state.model = gr;
					state.status = ApiStatus.Idle;
				}
			);
	},
});

export const {
	actions: {
		addMatchToProcess,
		nextStep,
		previousStep,
		setIsAdded,
		updateFormData,
	},
} = guidedRegistrationSlice;

export default guidedRegistrationSlice.reducer;
