import { ReactNode } from 'react';

import { PortalComponent } from 'library/components/portals';

import { SECTION_TITLE_BUTTONS_CONTAINER_ID } from './tabs';

export const SectionTitleButtons = ({ children }: { children: ReactNode }) => (
	<PortalComponent containerId={SECTION_TITLE_BUTTONS_CONTAINER_ID}>
		{children}
	</PortalComponent>
);
