import { Box, styled } from '@mui/material';
import { ComponentType, Suspense } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import { Spinner } from '../loaders';

export const PageContent = styled('div')({
	flex: '1 1 auto',
	overflowX: 'auto',
});

export interface PageContentTabParams {
	ErrorFallbackComponent: ComponentType<FallbackProps>;
}

export const PageContentTab = ({
	ErrorFallbackComponent,
}: PageContentTabParams) => {
	return (
		<Box
			sx={{
				flex: '1 1 auto',
				overflowX: 'auto',
				minHeight: 300,
			}}>
			<ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
				<Suspense fallback={<Spinner />}>
					<Outlet />
				</Suspense>
			</ErrorBoundary>
		</Box>
	);
};
