import { Box, BoxProps, Grid2 } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { getLocalizedDateTimeFormat } from 'library/formatters/DateFormatter';
import StringFormatter from 'library/formatters/StringFormatter';

import ChangeLanguageButton from './ChangeLanguageButton';

const copyrightYear = new Date().getFullYear();
const buildVersion = import.meta.env.VITE_BUILD_VERSION || '';
const deploymentDate = import.meta.env.VITE_DEPLOYMENT_DATE
	? getLocalizedDateTimeFormat(new Date(import.meta.env.VITE_DEPLOYMENT_DATE))
	: '';

const Footer = (props: BoxProps): JSX.Element => {
	const { t } = useTranslation(['layout']);

	return (
		<Box component="footer" id="footer" {...props}>
			<Grid2
				container
				sx={{
					mt: '-1px',
					bottom: 0,
					position: 'relative',
					fontSize: '0.875rem',
					borderTop: 1,
					borderColor: 'grey.200',
					boxSizing: 'border-box',

					display: {
						xs: 'none',
						sm: 'none',
						md: 'flex',
						lg: 'flex',
					},
				}}>
				<Grid2 size={{ md: 4, lg: 4 }} className={'footer__item'}>
					© Volvo Penta AB {copyrightYear}
				</Grid2>
				<Grid2
					size={{ md: 4, lg: 4 }}
					sx={{
						fontSize: '0.75rem',
						color: 'grey.200',
						alignItems: 'center',
						textAlign: 'center',
						overflow: 'hidden',
					}}
					className={'footer__item'}>
					{StringFormatter.Format(
						t('layout:footer-deployed-on-version'),
						buildVersion,
						deploymentDate
					)}
				</Grid2>
				<Grid2
					size={{ md: 4, lg: 4 }}
					sx={{
						textAlign: 'right',
					}}
					className={'footer__item'}>
					<ChangeLanguageButton />
				</Grid2>
			</Grid2>
		</Box>
	);
};

export default memo(Footer);
