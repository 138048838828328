import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DemoUseDto } from 'api';
import { TextDisplay } from 'library/components';
import { StyledLink, volvoWOPUrl } from 'library/components/link';
import { getLocalizedDateFormat } from 'library/formatters';

interface DemoDealerUsageProps {
	demo: DemoUseDto;
}

export const RegisterDemoDealerUsage = ({
	demo: { customer, registerBy, startDate },
}: DemoDealerUsageProps) => {
	const { t } = useTranslation(['common', 'eventLog', 'unitOverview']);
	const {
		palette: {
			secondary: { main: color },
		},
	} = useTheme();

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: { xs: 'column', sm: 'row' },
					rowGap: { xs: 2, sm: 0 },
					justifyContent: 'space-between',
					width: { md: '50%' },
				}}>
				<TextDisplay
					label={t('eventLog:drawer-form-label-demo-user-registered')}
					value={customer}
				/>
				<TextDisplay
					label={t(
						'eventLog:drawer-form-label-start-demo-user-registered'
					)}
					value={getLocalizedDateFormat(startDate)}
				/>
				<TextDisplay
					label={t('common:registered-by')}
					value={registerBy}
				/>
			</Box>
			<Typography
				variant="body2"
				sx={{
					mt: 6,
				}}>
				{t('unitOverview:demo-usage-registered-details')}
				<StyledLink
					href={volvoWOPUrl}
					target="_blank"
					sx={{ marginLeft: 0.5, color }}>
					{t('unitOverview:demo-usage-registered-details-link-text')}
				</StyledLink>
			</Typography>
		</>
	);
};
